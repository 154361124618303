<template>
  <div class="input-data-step">
    <h2>{{ $t("stockPickingLab.rebalanceModal.firstSectionTitle") }}</h2>
    <div class="content-text">
      {{ $t("stockPickingLab.rebalanceModal.firstSectionDescription") }}
    </div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form
        id="input-data-form"
        @submit.prevent="handleSubmit(handleFormSubmition)"
      >
        <select-box
          :name="$t('preferences.preferredStrategy')"
          :options="preferredStrategyOptions"
          v-model="selectedStrategyOption"
          label="name"
          :searchable="false"
          :clearable="false"
        />
        <ValidationProvider
          name="investedCapital"
          v-slot="{ errors, failed }"
          :rules="{
            regex: /[1-9]\d*/,
            min_value: {
              min: 5000,
            },
            max_value: 1000000000,
            required: true,
          }"
          mode="lazy"
        >
          <div class="form-item">
            <simple-form-input
              type="number"
              v-model="investedCapital"
              :label="$t('stockPicker.rebalanceForm.initialCapitalLabel')"
              :hasError="failed"
              :helperText="errors[0]"
            >
              <template v-slot:trailingIcon> $ </template>
            </simple-form-input>
          </div>
        </ValidationProvider>
      </form>
    </ValidationObserver>
    <div class="calculated-holder">
      <p>
        <span class="input-label"
          >{{ $t("stockPicker.calculatedShort") }}:</span
        >
        {{ shortPositions ? vueNumberFormat(shortPositions) : "$0.00" }}
      </p>
      <p>
        <span class="input-label">{{ $t("stockPicker.calculatedLong") }}:</span>
        {{ longPositions ? vueNumberFormat(longPositions) : "$0.00" }}
      </p>
    </div>
    <div class="rebalance-modal-animation">
      <img
        src="../../../assets/img/infographics/input_data_animation.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import {
  BALANCED_STRATEGY,
  LONG_ONLY,
  PREFERRED_STRATEGY_OPTIONS,
} from "../../../utils/constants";
import SelectBox from "../../inputs/SelectBox.vue";
import SimpleFormInput from "../../inputs/SimpleFormInput.vue";
import { mapGetters } from "vuex";
export default {
  name: "input-data-step",
  props: {
    defaultData: {
      type: [Number, String],
    },
  },
  data() {
    return {
      investedCapital: this.defaultData,
      shorts: 20,
      lever: 1.5,
      preferredStrategyOptions: PREFERRED_STRATEGY_OPTIONS(),
      selectedStrategyOption: null,
    };
  },
  methods: {
    handleFormSubmition() {
      this.$emit("submit", {
        investedCapital: this.investedCapital,
        selectedStrategyOption: this.selectedStrategyOption.value,
      });
    },
  },
  computed: {
    ...mapGetters({ user: "getUser" }),
    portfolio() {
      if (this.investedCapital) {
        return parseInt(this.investedCapital);
      }
      return null;
    },
    shortPositions() {
      if (
        this.portfolio &&
        this.selectedStrategyOption &&
        this.selectedStrategyOption.value === BALANCED_STRATEGY
      ) {
        const shorts_value = (this.portfolio * this.lever * 2) / 5;
        return Number(shorts_value / this.shorts).toFixed(2);
      }
      return null;
    },
    longPositions() {
      if (
        this.selectedStrategyOption &&
        this.selectedStrategyOption.value === LONG_ONLY
      ) {
        return Number(this.portfolio / 20).toFixed(2);
      } else if (this.shortPositions) {
        return Number(this.shortPositions * 1.5).toFixed(2);
      }
      return null;
    },
  },
  mounted() {
    this.selectedStrategyOption =
      PREFERRED_STRATEGY_OPTIONS().find(
        (str) => str.value === this.user.preferred_strategy
      ) || PREFERRED_STRATEGY_OPTIONS()[0];
  },
  components: {
    SimpleFormInput,
    SelectBox,
  },
};
</script>
<style lang="scss" scoped>
.input-data-step {
  .content-text {
    padding-bottom: 1rem;
    text-align: justify;
  }
  .calculated-holder {
    .input-label {
      font-weight: 500;
      color: $blueBorders;
    }
    display: flex;
    p {
      flex: 1;
      display: flex;
      justify-content: space-between;
      &:first-child {
        margin-right: toRem(10);
      }
    }
  }
}
</style>