var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ibkr-portfolio-form-container"},[_c('h2',[_vm._v(_vm._s(_vm.$t("stockPickingLab.rebalanceModal.secondSectionTitle")))]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"id":"ibkr-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleFormSubmition)}}},[_c('ValidationProvider',{attrs:{"name":"currentPortfolio","rules":"size:2000|ext:pdf|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
var failed = ref.failed;
return [_c('div',{staticClass:"form-item"},[_c('label',[_vm._v(" "+_vm._s(_vm.$t("stockPicker.ibkrUploadFile"))+" ")]),_c('b-form-file',{attrs:{"placeholder":_vm.currentPortfolio
                ? _vm.currentPortfolio.name
                : _vm.$t('stockPicker.rebalanceForm.currentPortfolioPlaceholder'),"drop-placeholder":"$t(\"stockPicker.rebalanceForm.currentPortfolioDroplaceholder\")","accept":"text/csv,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","state":failed ? false : null,"browse-text":_vm.$t('stockPicker.rebalanceForm.browse')},on:{"change":validate},model:{value:(_vm.currentPortfolio),callback:function ($$v) {_vm.currentPortfolio=$$v},expression:"currentPortfolio"}}),_c('p',{staticClass:"field-info",class:{ hasError: errors[0] }},[_vm._v(" "+_vm._s(((_vm.$t("stockPicker.rebalanceForm.acceptedFile")) + " " + (errors.length > 0 ? errors[0] : "")))+" ")])],1)]}}],null,true)})],1),_c('div',{staticClass:"rebalance-modal-animation"},[_c('img',{attrs:{"src":require("../../../assets/img/infographics/IBKR_rebalance.png"),"alt":""}})])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }