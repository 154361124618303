<template>
  <div class="ticker-container">
    <!--<img class="logo-container" :src="report.logo"/> -->
    <div class="name-container">
      <div class="flex">
        <div class="title" v-b-tooltip.hover :title="report.name">
          {{ report.symbol }}
        </div>
        <score-mark
          :score="report.score"
          :index="progress"
          :simpleScore="true"
          :length="length"
        />
      </div>
    </div>
    <div class="price-container">
      <div class="price">{{ vueNumberFormat(report.close_price) }}</div>
      <price-move :percentage="report.price_move" :simple="true" units="%" />
    </div>
  </div>
</template>

<script>
import * as constants from "../../../utils/constants";
import PriceMove from "./reportComponents/PercentageContainer.vue";
import ScoreMark from "./reportComponents/ScoreMark.vue";
export default {
  name: "TickerWithScore",
  components: {
    PriceMove,
    ScoreMark,
  },
  props: {
    report: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    length: {
      type: Number,
      required: true,
    },
  },
  computed: {
    progress() {
      if (
        this.report.direction.toLowerCase() ===
        constants.TICKER_LONG.toLowerCase()
      ) {
        return this.index;
      }
      return this.length - this.index;
    },
    priceMove() {
      if (this.report.price_move < 0) {
        return this.report.price_move.toString().substring(1);
      }
      return this.report.price_move;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.ticker-container {
  display: flex;
  flex-direction: row;
  margin-right: 1rem;
  width: 100%;
  padding: 0 0.5rem;
  align-items: center;
  margin-bottom: 0.5rem;
  height: 40px;
  min-width: calc(100% - 3rem);
  .logo-container {
    border-radius: 50%;
    margin-right: 0.5rem;
  }
  .name-container {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    padding-right: 0.2rem;
    .flex {
      align-items: center;
    }
    .score-mark-container {
      margin-left: 0.7rem;
      padding: 0.01rem;
    }
    .title {
      font-size: toRem(20);
      color: #333;
      font-weight: 600;
      min-width: 3.65rem;
    }
  }
  .price-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
}
</style>
