<template>
  <div class="performance-chart" :class="{ noData: !chartData }">
    <div class="chart-header" v-if="chartData">
      <div
        :class="[{ routing: routing }, 'report-type-head']"
        v-on="routing ? { click: handleGoToHistory } : {}"
      >
        <div class="chart-title">
          <report-type :type="timeframe" />
          {{ $t("stockPickingLab.historicalDataChart") }}
        </div>
        <div class="subtitle">{{ $t("stockPickingLab.chartSubtitle") }}</div>
      </div>
      <b-button variant="blank" @click="handleGoToHistory" v-if="routing">
        <history-icon :size="16" />
        {{ $t("stockPickingLab.viewHistoricalData") }}
      </b-button>
      <div class="flex">
        <span class="body-bold">
          {{ $t("stockPickingLab.averagePerformance") }}:
        </span>
        <percentage-container :percentage="Number(cagr)" units="%" />
      </div>
    </div>
    <v-chart
      :options="chartOptions"
      autoresize
      class="chart-component"
      :class="{ stockSelected: !!selectedStock }"
      v-if="chartData && !isEmpty"
    />
    <div class="no-data-available" v-else-if="chartData">
      <img src="../../assets/img/no_data.png" />
      {{ $t("stockPickingLab.chartNoAvailableData") }}
    </div>
    <b-spinner v-else />
  </div>
</template>

<script>
import * as reportConstants from "../../utils/constants";
import { GridComponent, TooltipComponent } from "echarts/components";
import { graphic, use } from "echarts/core";
import { mapActions, mapGetters } from "vuex";
import HistoryIcon from "vue-material-design-icons/History.vue";
import { LineChart } from "echarts/charts";
import PercentageContainer from "../tables/tableComponents/reportComponents/PercentageContainer.vue";
import ReportType from "../tables/tableComponents/reportComponents/ReportType.vue";
import VChart from "vue-echarts";
import { formatDateToCommon } from "../../utils/helper";
import { getTypeTranslation } from "../../utils/translationHelper";

use([GridComponent, TooltipComponent, LineChart]);

export default {
  components: { HistoryIcon, PercentageContainer, ReportType, VChart },
  name: "performanceChart",
  props: {
    strategy: {
      type: String,
    },
    timeframe: {
      type: String,
    },
    routing: {
      type: Boolean,
      default: true,
    },
    hasRebalanced: {
      type: Boolean,
    },
  },
  data() {
    return {
      cagr: 0,
      isEmpty: false,
      chartOptions: {
        grid: {
          left: 35,
          top: 35,
          right: 55,
          bottom: 35,
        },
        tooltip: {
          trigger: "axis",
          position: function (pt) {
            return [pt[0], "10%"];
          },
          valueFormatter: (value) => value + "%",
        },
        xAxis: {
          type: "category",
          data: [],
          boundaryGap: false,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          position: "right",
          splitLine: {
            show: false,
          },
          axisLabel: {
            formatter: function (val) {
              return val + "%";
            },
          },
        },
        series: [
          {
            data: [],
            type: "line",
            itemStyle: {
              color: this.chartColor(),
            },
            areaStyle: {
              opacity: 0.8,
              color: new graphic.LinearGradient(0, 0, 0, 0.8, [
                {
                  offset: 0,
                  color: this.chartColor(),
                },
                {
                  offset: 1,
                  color: "rgba(255, 255, 255,0)",
                },
              ]),
            },
          },
        ],
      },
    };
  },
  methods: {
    ...mapActions("stockpickingLabStore", ["getChartData"]),
    getTypeTranslation,
    handleFetchChartData() {
      return this.getChartData({
        timeframe: this.timeframe,
        strategy: this.strategy,
      });
    },
    populateChartData() {
      const separatedData =
        this.chartData?.multiplied !== null
          ? Object.entries(this.chartData.multiplied)
          : [];
      this.isEmpty = separatedData.length === 0;
      this.chartOptions.xAxis.data = separatedData.map((pair) =>
        formatDateToCommon(pair[0])
      );
      const chartValues = separatedData.map((pair) =>
        (pair[1] - 100).toFixed(2)
      );
      this.calculateCagr(chartValues);
      this.chartOptions.series[0].data = chartValues;
    },
    calculateCagr(values) {
      const valuesNumber = values.map(Number);
      this.cagr = (
        (Math.pow(
          (valuesNumber.slice(-1)[0] * 0.01 + 1) / 1,
          1 / (valuesNumber.length / 252)
        ) -
          1) *
        100
      ).toFixed(2);
    },
    chartColor() {
      switch (this.timeframe) {
        case reportConstants.DAILY_REPORT:
          return "#17C59B";
        case reportConstants.MONTHLY_REPORT:
          return "#B019D6";
        case reportConstants.YEARLY_REPORT:
          return "#FF8B4A";
        default:
          return "#3687FF";
      }
    },
    handleGoToHistory() {
      this.$router.push({
        name: "reportList",
        params: {
          timeframe: this.timeframe,
        },
        query: {
          strategy: this.strategy,
        },
      });
    },
  },
  computed: {
    ...mapGetters("stockpickingLabStore", ["chartData", "selectedStock"]),
  },
  mounted() {
    this.handleFetchChartData().then(() => this.populateChartData());
  },
  watch: {
    timeframe() {
      this.handleFetchChartData().then(() => this.populateChartData());
    },
    strategy() {
      this.handleFetchChartData().then(() => this.populateChartData());
    },
  },
};
</script>

<style lang="scss" scoped>
.performance-chart {
  box-shadow: (0px 0px 15px #b1d0ff);
  border-radius: 10px;
  min-height: 400px;
  margin-bottom: toRem(30);
  border-radius: 4px;
  padding: toRem(10);
  padding-top: toRem(15);
  &.noData {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .no-data-available {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: toRem(250);
      height: auto;
    }
  }
  .chart-component {
    width: 100%;
    &.stockSelected {
      width: calc(100% - 1px);
    }
  }
  width: 100%;
  .chart-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 10px;
    cursor: context-menu;
    .price-move-container {
      margin-left: toRem(10);
    }
  }
  .report-type-head {
    &.routing {
      cursor: pointer;
    }
    display: flex;
    flex-direction: column;
    .chart-title {
      display: flex;
      align-items: center;
      font-weight: bold;
    }
    .subtitle {
      padding-top: toRem(8);
      font-style: italic;
      color: #bdbdbb;
    }
    .report-type {
      margin-right: toRem(8);
    }
  }
  .performance-highlight {
    color: $blueBorders;
    font-size: toRem(16);
    font-weight: 600;
  }
  .btn {
    margin-left: auto;
    padding: 0 toRem(15);
    display: flex;
    align-items: center;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
