<template>
  <div class="stock-replacer">
    <horizontal-title-tooltip-section
      :title="$t('stockPickingLab.replacerForm.titles')"
      :tooltip="$t('stockPickingLab.replacerForm.hint')"
      v-if="!replacementStock"
    />
    <simple-form-input
      v-model="query"
      :label="$t('general.search')"
      v-if="!replacementStock"
    >
      <template v-slot:leadingIcon>
        <magnify-icon />
      </template>
    </simple-form-input>
    <div class="replacements-list" v-if="!replacementStock">
      <ticker-with-image
        v-for="ticker in replacementOptions"
        :key="ticker.id"
        :globalIndex="activeDetail.report_details.indexOf(ticker)"
        :allTickersLength="activeDetail.report_details.length"
        :report="ticker"
        inSidebar
        @click="
          setReplacementStock({
            report: ticker,
            globalIndex: activeDetail.report_details.indexOf(ticker),
            allTickersLength: activeDetail.report_details.length,
          })
        "
      />
    </div>
    <div class="confirmation" v-else>
      <div class="icon-devider">
        <swap-icon :size="20" />
      </div>
      <ticker-with-image
        :globalIndex="replacementStock.globalIndex"
        :allTickersLength="replacementStock.allTickersLength"
        :report="replacementStock.report"
        inSidebar
      />
      <ticker-details :stock="replacementStock" />
      <b-button class="confirm-btn" @click="handleConfirmReplacement">
        {{ $t("stockPickingLab.replacerForm.confirmReplacement") }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import HorizontalTitleTooltipSection from "./inputs/HorizontalTitleTooltipSection.vue";
import MagnifyIcon from "vue-material-design-icons/Magnify.vue";
import SimpleFormInput from "./inputs/SimpleFormInput.vue";
import SwapIcon from "vue-material-design-icons/SwapVerticalVariant.vue";
import TickerDetails from "./tables/tableComponents/TickerDetails.vue";
import TickerWithImage from "./tables/tableComponents/TickerWithImage.vue";

export default {
  name: "stock-replacer",
  components: {
    HorizontalTitleTooltipSection,
    TickerWithImage,
    SimpleFormInput,
    SwapIcon,
    MagnifyIcon,
    TickerDetails,
  },
  data() {
    return {
      query: "",
    };
  },
  computed: {
    ...mapGetters("stockpickingLabStore", [
      "activeDetail",
      "selectedStock",
      "longTickers",
      "shortTickers",
      "discardedTickers",
      "replacementStock",
    ]),
    replacementOptions() {
      return this.activeDetail.report_details.filter(
        (stock) =>
          stock.direction === this.selectedStock.report.direction &&
          !this.longTickers.includes(stock) &&
          !this.shortTickers.includes(stock) &&
          !this.discardedTickers.map((t) => t.report).includes(stock) &&
          (stock.symbol.includes(this.query.toUpperCase()) ||
            stock.name.toUpperCase().includes(this.query.toUpperCase()))
      );
    },
  },
  methods: {
    ...mapMutations("stockpickingLabStore", [
      "setReplacerActive",
      "swapStocks",
      "clearSelectedStock",
      "setReplacementStock",
    ]),
    handleConfirmReplacement() {
      this.swapStocks({
        in: this.replacementStock,
        out: this.selectedStock,
      });
      this.setReplacementStock(null);
      this.clearSelectedStock();
      this.setReplacerActive(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.replacements-list {
  overflow-y: scroll;
  max-height: 100vh;
  &::-webkit-scrollbar {
    display: none;
  }
}
.icon-devider {
  padding: 1rem 0;
  text-align: center;
}
.confirm-btn {
  margin: 1rem auto;
}
</style>