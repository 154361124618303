<template>
  <div class="modal-navigation">
    <p class="nav-title">{{ $t("stockPickingLab.rebalanceModal.subtitle") }}</p>
    <p class="subtitle-section">
      <report-type :type="type" />
      <span class="report-date">
        {{ formatDateToCommon(date) }}
      </span>
      <tooltip-info
        v-if="isLagging"
        :title="`${$t(
          'stockPickingLab.rebalanceModal.reportLags'
        )} ${formatDateWithTime(this.lastUpdated)}`"
        fillColor="#e82127"
      >
      </tooltip-info>
    </p>
    <ul>
      <modal-nav-item :step="0" :currentStep="currentStep">
        {{ $t("stockPickingLab.rebalanceModal.nav.firstSection") }}
      </modal-nav-item>
      <modal-nav-item :step="1" :currentStep="currentStep">
        {{ $t("stockPickingLab.rebalanceModal.nav.secondSection") }}
      </modal-nav-item>
      <modal-nav-item :step="2" :currentStep="currentStep">
        {{ $t("stockPickingLab.rebalanceModal.nav.thirdSection") }}
      </modal-nav-item>
    </ul>
  </div>
</template>

<script>
import { DATE_TIME_FORMAT, EXPIRED_REPORT } from "../../utils/constants";
import ModalNavItem from "./ModalNavItem.vue";
import ReportType from "../tables/tableComponents/reportComponents/ReportType.vue";
import TooltipInfo from "../TooltipInfo.vue";
import { formatDateToCommon } from "../../utils/helper";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  components: { ModalNavItem, ReportType, TooltipInfo },
  name: "modal-navigation",
  props: {
    currentStep: {
      type: Number,
    },
    date: {
      type: String,
    },
    type: {
      type: String,
    },
    lastUpdated: {
      type: String,
    },
    state: {
      type: String,
    },
  },
  methods: {
    formatDateToCommon,
    formatDateWithTime(date) {
      return moment(date).format(`${DATE_TIME_FORMAT()} HH:mm`);
    },
  },
  computed: {
    ...mapGetters("stockpickingLabStore", {
      tradingHours: "getMarketTradingHours",
    }),
    isMarketOpen() {
      const subtractFrom = moment(this.tradingHours.market_open).isAfter(
        moment()
      )
        ? this.tradingHours.market_open
        : this.tradingHours.market_close;
      if (this.tradingHours.market_close === subtractFrom) {
        return true;
      } else {
        return false;
      }
    },
    isLagging() {
      return (
        moment(this.lastUpdated).diff(moment(), "hours") <= -1 &&
        this.state !== EXPIRED_REPORT &&
        this.isMarketOpen
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-navigation {
  width: toRem(200);
  padding: 24px 13px;
  border-right: 1px solid $separator;
  ul {
    list-style-type: none;
    padding: 0;
  }
  .nav-title {
    font-weight: 500;
    color: $blueBorders;
    font-size: toRem(14);
    margin-bottom: 0.2rem;
  }
  .subtitle-section {
    display: flex;
    align-items: center;
    font-size: 9px;
    margin-bottom: 0.8rem;
    .report-type {
      margin-right: 0.3rem;
      width: 16px;
      min-width: 16px;
      height: 16px;
      font-size: 9px;
    }
    .report-date {
      margin-right: 3px;
    }
  }
}
</style>