<template>
  <div class="container-footer">
    <div>
      <b-button v-if="showCSV" variant="primary" class="text-button" v-on:click="csvExport()"> <SaveIcon :size="20" />Download to CSV </b-button>
    </div>
    <div class="table-footer">
      <div class="page-size-container">
        {{ $t("general.rowsPerPage") }}
        <select v-model="pageSize" @change="handlePerPageChange($event)" data-cy="select-pageSize">
          <option v-for="size in pageSizes" :key="size" :value="size">
            {{ size }}
          </option>
        </select>
      </div>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="pageSize"
        :limit="limit"
        @change="handlePageChange"
        aria-controls="reports-table"
        pills
        size="sm"
      >
        <template #first-text
          ><span class="pagination-item"><first-icon /></span
        ></template>
        <template #prev-text
          ><span class="pagination-item"><prev-icon /></span
        ></template>
        <template #next-text
          ><span class="pagination-item"><next-icon /></span
        ></template>
        <template #last-text
          ><span class="pagination-item"><last-icon /></span
        ></template>
      </b-pagination>
    </div>
  </div>
</template>
<script>
import FirstIcon from "vue-material-design-icons/PageFirst.vue";
import LastIcon from "vue-material-design-icons/PageLast.vue";
import NextIcon from "vue-material-design-icons/ChevronRight.vue";
import PrevIcon from "vue-material-design-icons/ChevronLeft.vue"
import SaveIcon from "vue-material-design-icons/ContentSaveOutline.vue"
export default {
  components: {
    FirstIcon,
    LastIcon,
    NextIcon,
    PrevIcon,
    SaveIcon,
  },
  props: {
    pageSizes:{
        type: Array,
        required: true
    },
    page: {
        type: Number,
        required: true
    },
    perPage: {
        type: Number,
        required: true
    },
    totalRows: {
        type: Number,
        default: 0
    },
    limit: {
        type:Number,
        default: 5
    },
    export: {
      type: Boolean,
      default: false
    },
    exportName: {
      type: String
    },
    exportUrl: {
      type: String
    }
  },
  data() {
    return {
      users: [],
      query: "",
      currentPage: this.page || 1,
      pageSize: this.perPage || 10,
      showCSV: this.export,
    };
  },
  methods: {
    handlePerPageChange(event) {
        this.pageSize = Number(event.target.value);
        this.currentPage = 1;
        this.$emit("perPageChange", this.pageSize);
        this.$emit("pageChange", this.currentPage);
    },
    handlePageChange(value) {
      this.currentPage = value;
      this.$emit("pageChange", this.currentPage);
    },
    csvExport() {
        var link = document.createElement("a");
        link.download = this.exportName + ".csv";
        link.href = this.exportUrl? this.exportUrl : "data:text/csv,";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
  },
   watch: {
    page(newValue, oldValue){
      if(newValue !== oldValue){
        this.currentPage = newValue;
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.container-footer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .text-button{
    color: $blueBorders;
    margin-left: toRem(16);
  }
  .table-footer {
      display: flex;
      align-items: center;
      height: toRem(48);
      justify-content: flex-end;
      .page-size-container {
        margin-right: 5rem;
        color: #4f4f4f;
        font-weight: 600;
      }
      .pagination {
        margin-bottom: 0;
        .page-link {
          border: none;
        }
      }
    }
}
</style>