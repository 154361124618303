<template>
  <ul class="main-tickers">
    <div class="tickers-wrapper">
      <li v-for="item in items" :key="item.ticker">
        <ticker
          :report="getItem(item)"
          :globalIndex="itemIndex(item)"
          :allTickersLength="listLength(item)"
          :listType="listType"
          :canRebalance="canRebalance"
          :canDelete="canDelete"
          :simple="true"
          :priceMoveTimeFrame="priceMoveTimeFrame"
          @click="
            setSelectedStock({
              report: getItem(item),
              globalIndex: itemIndex(item),
              allTickersLength: listLength(item),
            })
          "
        />
      </li>
    </div>
  </ul>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import Ticker from "./tables/tableComponents/TickerWithImage.vue";
export default {
  name: "CardSlider",
  components: {
    Ticker,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    parentItems: Array,
    canRebalance: Boolean,
    canDelete: Boolean,
    priceMoveTimeFrame: String,
    listType: String,
  },
  methods: {
    ...mapMutations("stockpickingLabStore", ["setSelectedStock"]),
    getItem(item) {
      if (item.report) {
        return item.report;
      }
      return item;
    },
    listLength(item) {
      if (item.allTickersLength) {
        return item.allTickersLength;
      }
      return this.parentItems ? this.parentItems.length : this.items.length;
    },
    itemIndex(item) {
      if (item.globalIndex) {
        return item.globalIndex;
      }
      return this.parentItems
        ? this.parentItems.indexOf(item)
        : this.items.indexOf(item);
    },
  },
  computed: {
    ...mapGetters({ user: "getUser" }),
    ...mapGetters("stockpickingLabStore", ["activeDetail", "discardedTickers"]),
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.all-columns {
  .tickers-wrapper {
    column-gap: 100px;
    li {
      width: calc(1 / 5 * 100% - (1 - 1 / 5) * 100px);
    }
  }
}
.tickers-wrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  li {
    width: calc(1 / 3 * 100% - (1 - 1 / 3) * 40px);
  }
}
ul {
  padding-left: 0;
}
li {
  list-style-type: none;
}
.ticker-container {
  margin-right: 0.5rem;
}
@media only screen and (max-width: 1900px) {
  .all-columns {
    .tickers-wrapper {
      li {
        width: calc(1 / 4 * 100% - (1 - 1 / 4) * 100px);
      }
    }
  }
}
@media only screen and (max-width: 1600px) {
  .tickers-wrapper {
    column-gap: 20px;
    li {
      width: calc(1 / 2 * 100% - (1 - 1 / 2) * 20px);
    }
  }
}

@media only screen and (max-width: 1400px) {
  .all-columns {
    .tickers-wrapper {
      li {
        width: calc(1 / 3 * 100% - (1 - 1 / 3) * 100px);
      }
    }
  }
}
</style>
