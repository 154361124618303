<template>
  <div
    :class="[
      { simple: simpleScore, noScore: hideScore },
      'score-mark-container',
    ]"
    v-b-tooltip.hover
    :title="getDescription()"
  >
    <div :class="getMarkClass()">
      <span>{{ getMark() }} </span>
      <span v-if="simpleScore" class="value-container"> {{ scoreFormatted }} </span>
    </div>
    <div v-if="showTitle" class="mark-description"> 
      <span>{{ getDescription() }} </span>
      <span class="value-container"> {{ scoreFormatted }} </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "ScoreMark",
  props: {
    score: {
      type: Number,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    simpleScore: {
      type: Boolean,
      default: false,
      description: "grade with number together"
    },
    length: {
      type: Number,
      required: true,
    },
    hideScore: {
      type: Boolean,
    },
    showTitle: {
      type: Boolean, 
      default: false,
      description: "grade, name, score"
    }
  },
  computed: {
    scoreFormatted() {
      return Math.round(this.score * 100);
    },
  },
  methods: {
    getMarkClass() {
      const className = "mark-container";
      if (this.index < 20) {
        return className + " a-mark";
      }
      if (this.index < 40) {
        return className + " b-mark";
      }
      if (this.index >= this.length - 20) {
        return className + " e-mark";
      }
      if (this.index >= this.length - 40) {
        return className + " d-mark";
      }
      return className + " c-mark";
    },
    getMark() {
      if (this.index < 20) {
        return "A";
      }
      if (this.index < 40) {
        return "B";
      }

      if (this.index >= this.length - 20) {
        return "E";
      }
      if (this.index >= this.length - 40) {
        return "D";
      }
      return "C";
    },
    getDescription() {
      if (this.index < 20) {
        return this.$i18n.t("general.mostUndervalued");
      }
      if (this.index < 40) {
        return this.$i18n.t("general.undervalued");
      }
      if (this.index >= this.length - 20) {
        return this.$i18n.t("general.mostOvervalued");
      }
      if (this.index >= this.length - 40) {
        return this.$i18n.t("general.overvalued");
      }
      return this.$i18n.t("general.neutral");
    },
  },
};
</script>
<style lang="scss" scoped>
.score-mark-container {
  display: flex;
  border-radius: 4px;
  align-items: center;
  width: fit-content;
  pointer-events: none;
  &.simple {
    width: fit-content;
    padding: 0.05rem;
    padding-right: 0.25rem;
    box-shadow: none;
    border: none;
    pointer-events: auto;
    .mark-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: fit-content;
      padding: 0 0.5rem;
      span {
        font-size: toRem(16);
      }
      .value-container{
        font-size: toRem(12);
        padding-top: 0.1em;
        margin-left: toRem(5);
      }
    }
  }
  &.noScore {
    padding-right: 0.05rem;
  }
  .mark-container {
    width: toRem(30);
    border-radius: 4px;
    text-align: center;
    span {
      font-size: toRem(18);
      color: #fff;
    }
    &.a-mark {
      background: #219653;
    }
    &.b-mark {
      background: #82ba22;
    }
    &.c-mark {
      background: #fdb92c;
    }
    &.d-mark {
      background: #fd832c;
    }
    &.e-mark {
      background: #f0502a;
    }
  }
  .mark-description {
    margin-left: 1rem;
    font-size: toRem(12);
    font-weight: 500;
    color: $textBlack;
    width: calc(100% - 30px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .value-container{
      font-size: toRem(14);
    }
  }
  .value-container {
    margin-left: auto;
    color: $textBlack;
  }
}
</style>