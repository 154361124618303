<template>
  <div
    class="rebalance-progress"
    :class="{ notRebalanced: !hasRebalanced && !isExpired }"
  >
    <div class="dates">
      <horizontal-value-tooltip-section
        :value="formatDateToCommon(validFrom)"
        :name="$t('general.validFrom')"
        :tooltip="$t('stockPickingLab.tooltip.valid_from')"
        :bold="true"
        titleStyle="italic"
      />
      <report-indicator
        :type="reportType"
        v-if="reportType === OUTDATED_REPORT && !hasRebalanced"
      />
      <horizontal-value-tooltip-section
        v-if="!isExpired"
        :value="formatDateToCommon(expiration)"
        :name="$t('general.expirationDate')"
        :tooltip="$t('stockPickingLab.tooltip.expiration')"
        :bold="true"
        titleStyle="italic"
      />
      <div v-else class="expired-msg">
        <warning-icon :size="20" />
        <span class="msg">
          {{ $t("stockPickingLab.expiredReport") }}
        </span>
        <tooltip-info :title="$t('stockPickingLab.tooltip.expiration')" />
      </div>
    </div>
    <b-progress
      :value="value"
      :max="max"
      :class="{ isExpired }"
      v-if="hasRebalanced || isExpired"
    />
    <div class="current-performance" v-if="!hasRebalanced && !isExpired">
      <span>{{ $t("stockPickingLab.currentPerformance") }}</span>
      <percentage-container :percentage="profit" units="%" />
    </div>
  </div>
</template>

<script>
import HorizontalValueTooltipSection from "./inputs/HorizontalValueTooltipSection.vue";
import { OUTDATED_REPORT } from "../utils/constants";
import PercentageContainer from "./tables/tableComponents/reportComponents/PercentageContainer.vue";
import ReportIndicator from "./tables/tableComponents/reportComponents/ReportIndicator.vue";
import TooltipInfo from "./TooltipInfo.vue";
import WarningIcon from "vue-material-design-icons/AlertOutline.vue";
import { formatDateToCommon } from "../utils/helper";
import moment from "moment";

export default {
  components: {
    HorizontalValueTooltipSection,
    WarningIcon,
    TooltipInfo,
    PercentageContainer,
    ReportIndicator,
  },
  name: "rebalance-progress",
  props: {
    validFrom: String,
    expiration: String,
    hasRebalanced: Boolean,
    reportType: String,
    profit: Number,
  },
  data() {
    return {
      value: moment().diff(this.validFrom, "hours"),
      max: moment(this.expiration).diff(this.validFrom, "hours"),
      OUTDATED_REPORT,
    };
  },
  methods: {
    formatDateToCommon,
  },
  computed: {
    isExpired() {
      return moment().isAfter(moment(this.expiration).hour(22));
    },
  },
};
</script>

<style lang="scss" >
.rebalance-progress {
  margin-top: toRem(-15);
  width: 100%;
  // margin-bottom: toRem(15);
  &.notRebalanced {
    justify-content: flex-start;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    h3 {
      margin-bottom: 0;
      margin-right: toRem(50);
    }
    .dates {
      margin-bottom: toRem(3);
      min-width: toRem(400);
      .report-status {
        margin: 0 toRem(10);
      }
    }
    .current-performance {
      display: flex;
      margin-left: auto;
      align-items: center;
      span {
        margin-right: 5px;
      }
    }
  }
  .expired-msg {
    color: $errorRedBorder;
    display: flex;
    .msg {
      margin: 0 3px;
    }
  }
  .horizontal-value-tooltip {
    padding-right: 0;
  }
  .progress {
    background-color: white;
    border: 1px solid $blueBorders;
    border-radius: $navItemRadius;
    width: 100%;
    &.isExpired {
      margin-bottom: toRem(15);
      .progress-bar {
        background-image: linear-gradient(
          -45deg,
          rgba(255, 255, 255, 0.15) 25%,
          white 25%,
          white 50%,
          rgba(255, 255, 255, 0.15) 50%,
          rgba(255, 255, 255, 0.15) 75%,
          white 75%,
          white
        );
        background-size: 1rem 1rem;
        background-color: $separator;
      }
    }
  }
  .progress-bar {
    background-image: linear-gradient(to right, #c9defe, $primaryColor);
  }
  .dates {
    margin-top: toRem(8);
    display: flex;
    justify-content: space-between;
  }
}
</style>