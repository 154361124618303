<template>
  <div class="report-performance-chart">
    <div class="chart-content" v-if="rebalancedReportChart">
      <div class="chart-header">
        <div class="body-bold">
          {{ $t("stockPickingLab.reportPerformanceChartTiltle") }}
        </div>
        <div class="flex">
          <span class="body-bold"> {{ $t("general.performance") }}: </span>
          <percentage-container
            :percentage="Object.keys(rebalancedReportChart).length !== 0 ? 
              parseFloat(
                parseFloat(chartOptions.series[0].data.slice(-1).pop()).toFixed(
                  2
                )
              )
              : 0
            "
            units="%"
          />
        </div>
      </div>
      <v-chart :options="chartOptions" autoresize class="chart-component" />
    </div>
    <div class="other-state" v-else>
      <b-spinner />
    </div>
  </div>
</template>

<script>
import { GridComponent, TooltipComponent } from "echarts/components";
import { graphic, use } from "echarts/core";
import { mapActions, mapGetters } from "vuex";
import { LineChart } from "echarts/charts";
import PercentageContainer from "../tables/tableComponents/reportComponents/PercentageContainer.vue";
import VChart from "vue-echarts";
import { formatDateToCommon } from "../../utils/helper";
import moment from "moment";

use([GridComponent, TooltipComponent, LineChart]);

export default {
  components: { PercentageContainer, VChart },
  name: "report-performance-chart",
  props: {
    isExpired: Boolean,
    selectedStrategy: Object,
  },
  data() {
    return {
      chartOptions: {
        grid: {
          left: 35,
          top: 35,
          right: 55,
          bottom: 35,
        },
        tooltip: {
          trigger: "axis",
          position: function (pt) {
            return [pt[0], "10%"];
          },
          valueFormatter: (value) => value + "%",
        },
        xAxis: {
          type: "category",
          data: [],
          boundaryGap: false,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          position: "right",
          splitLine: {
            show: false,
          },
          axisLabel: {
            formatter: function (val) {
              return val + "%";
            },
          },
          min: "dataMin",
          max: "dataMax",
        },
        series: [
          {
            data: [],
            type: "line",
            itemStyle: {
              color: this.chartColor(),
            },
            areaStyle: {
              opacity: 0.8,
              color: new graphic.LinearGradient(0, 0, 0, 0.8, [
                {
                  offset: 0,
                  color: this.chartColor(),
                },
                {
                  offset: 1,
                  color: "rgba(255, 255, 255,0)",
                },
              ]),
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("stockpickingLabStore", [
      "rebalancedReportChart",
      "activeDetail",
    ]),
  },
  methods: {
    ...mapActions("stockpickingLabStore", ["getRebalancedReportChartData"]),
    populateData() {
      if (this.rebalancedReportChart) {
        this.chartOptions.series[0].data = Object.values(
          this.rebalancedReportChart
        ).map((val) => (val - 100).toFixed(2));
        const apiDates = Object.keys(this.rebalancedReportChart);
        const lastApi = moment(apiDates.slice(-1)[0]);
        const expiration = moment(this.activeDetail.expiration);
        const daysDiff = expiration.diff(lastApi, "days");
        const genDates = [];
        for (let i = 0; i <= daysDiff; i++) {
          const newDate = lastApi.clone().add(i, "day");
          genDates.push(formatDateToCommon(newDate));
        }
        this.chartOptions.xAxis.data = [
          ...apiDates.map((date) => formatDateToCommon(date)),
          ...genDates,
        ];
      }
    },
    chartColor() {
      return this.isExpired ? "#BDBDBD" : "#3687ff";
    },
  },
  mounted() {
    this.getRebalancedReportChartData({
      id: this.activeDetail.id,
      strategy: this.selectedStrategy.value,
    });
  },
  watch: {
    rebalancedReportChart() {
      this.populateData();
    },
  },
};
</script>

<style lang="scss" scoped>
.report-performance-chart {
  .chart-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 10px;
    min-height: 50px;
    cursor: context-menu;
    .price-move-container {
      margin-left: toRem(10);
    }
  }
  box-shadow: (0px 0px 15px #b1d0ff);
  border-radius: 10px;
  min-height: 400px;
  margin-bottom: toRem(30);
  border-radius: 4px;
  padding: toRem(10);
  padding-top: toRem(15);
  .chart-component {
    width: 100%;
  }
  .other-state {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>