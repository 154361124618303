<template>
  <div v-if="selectedStock" class="stock-sidebar">
    <div class="bar-heading">
      <p class="main-title">{{ $t("stockPickingLab.stockDetail") }}</p>
      <close-icon @click="handleClose" />
    </div>
    <ticker-with-image
      v-bind="selectedStock"
      :nameOnly="!replacerActive"
      inSidebar
    />
    <b-button
      v-if="
        canRebalance &&
        !replacerActive &&
        canDelete &&
        !discarded &&
        !hasRebalanced &&
        strategyAccess
      "
      variant="secondary"
      @click="setReplacerActive(true)"
    >
      <recycle-icon :size="20" />
      <span> {{ $t("stockPickingLab.replaceStock") }}</span>
    </b-button>
    <ticker-details
      :stock="selectedStock"
      v-if="!replacerActive || replacementStock"
      :priceMove="priceMove"
      :openPrice="openPrice"
      :closePrice="closePrice"
      :priceDefault="priceDefault"
      :priceTime="'(' + priceMoveTimeFrame.name + ')'"
    />
    <stock-replacer v-if="replacerActive" />
  </div>
</template>

<script>
import * as constants from "../../utils/constants";
import { mapActions, mapGetters, mapMutations } from "vuex";
import CloseIcon from "vue-material-design-icons/Close.vue";
import RecycleIcon from "vue-material-design-icons/Cached.vue";
import StockReplacer from "../StockReplacer.vue";
import TickerDetails from "../tables/tableComponents/TickerDetails.vue";
import TickerWithImage from "../tables/tableComponents/TickerWithImage.vue";
import moment from "moment";
export default {
  name: "stock-sidebar",
  components: {
    CloseIcon,
    RecycleIcon,
    TickerDetails,
    TickerWithImage,
    StockReplacer,
  },
  props: {
    hasRebalanced: Boolean,
    strategyAccess: Boolean,
    priceMoveTimeFrame: Object,
  },
  computed: {
    ...mapGetters("stockpickingLabStore", [
      "selectedStock",
      "discardedTickers",
      "replacerActive",
      "replacementStock",
      "stockPrices"
    ]),
    ...mapGetters({ user: "getUser" }),
    priceDefault(){
      return this.priceMoveTimeFrame.id === constants.DEFAULT;
    },
    openPrice() {
      if(!this.priceDefault){
        const index = this.priceMoveTimeFrame.id;
        if(this.stockPrices.length === 0 || this.stockPrices[index] === null){
          return this.$i18n.t("general.unavailable");
        }
        return "$" + (this.stockPrices[index]).toFixed(2);
      }
      return "$" + this.selectedStock.report.open_price;
    },
    closePrice() {
      if(!this.priceDefault){
        if(this.stockPrices.length === 0 || this.stockPrices.close_last === null){
          return this.$i18n.t("general.unavailable");
        }
        return "$" +(this.stockPrices.close_last).toFixed(2);
      }
      return "$" + this.selectedStock.report.close_price;
    },
    priceMove() {
      switch (this.priceMoveTimeFrame.id) {
        case constants.DAY:
          return Number(this.selectedStock.report.price_move_1d);
        case constants.FIVE_DAYS:
          return Number(this.selectedStock.report.price_move_5d);
        case constants.MONTH:
          return Number(this.selectedStock.report.price_move_1m);
        case constants.DEFAULT:
          return Number(this.selectedStock.report.price_move);
        default: {
          if (this.strategyAccess){
            return Number(this.selectedStock.report.price_move);
          }
          return Number(this.selectedStock.report.price_move_1d);
        }
      }
    },
    canRebalance() {
      return this.user.permissions.includes(constants.REBALANCE_PRIVILEGE);
    },
    discarded() {
      return this.discardedTickers.find((element) => {
        return element.report.id === this.selectedStock.report.id;
      });
    },
    canDelete() {
      return (
        this.selectedStock.report.direction ===
          constants.TICKER_LONG.toUpperCase() && this.selectedStock.report.id
      );
    },
  },
  methods: {
     ...mapActions("stockpickingLabStore", ["getPriceForStock"]),
    ...mapMutations("stockpickingLabStore", [
      "setSelectedStock",
      "moveTickerToDiscarded",
      "moveTickerToRebalance",
      "setReplacerActive",
      "clearSelectedStock",
      "setReplacementStock",
    ]),
    handleMoveTicker() {
      const data = this.selectedStock;
      if (!this.discarded) {
        this.moveTickerToDiscarded(data);
      } else {
        this.moveTickerToRebalance(data);
      }
    },
    handleClose() {
      this.clearSelectedStock();
      this.setReplacementStock(null);
    },
  },
  mounted(){
    if(this.selectedStock !== null){
      this.getPriceForStock({
        symbol: this.selectedStock.report.symbol,
        date: moment().subtract(2, 'M').format("YYYYMMDD"),
      });
    }
  },
  destroyed() {
    this.clearSelectedStock();
  },
  watch: {
    selectedStock(){
      if(this.selectedStock !== null){
        this.getPriceForStock({
          symbol: this.selectedStock.report.symbol,
          date: moment().subtract(2, 'M').format("YYYYMMDD"),
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.stock-sidebar {
  position: fixed;
  width: inherit;
  padding-right: 2rem;
  .bar-heading {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-left: toRem(-5);
    .main-title {
      margin-bottom: toRem(5);
      font-weight: bold;
      font-size: toRem(13);
    }
  }
  .close-icon {
    cursor: pointer;
    height: 16px;
    width: 16px;
  }
  .btn {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: toRem(12);
  }
}
@media only screen and (max-width: 1023px) {
  .stock-sidebar {
    position: relative;
    padding-top: toRem(35);
    padding-right: 0;
  }
}
@media only screen and (max-width: 800px) {
  .stock-sidebar {
    padding-top: toRem(65);
  }
}
</style>