<template>
  <div class="table-container" :id="id.toLowerCase()">
    <b-table
      id="reports-table"
      :ref="id"
      class="reports-table"
      show-empty
      fixed
      :items="reports"
      :fields="headings"
      :busy="isLoading"
      v-model="currentItems"
      :tbody-tr-class="rowClass"
      :no-local-sorting="true"
      sort-icon-left
      @sort-changed="handleSortingChange"
      @row-clicked="(item) => handleRowClick(item)"
    >
      <template v-slot:table-busy>
        <div class="table-loader">
          <b-spinner class="spinner"></b-spinner>
          <span>{{ $t("general.loading") }}...</span>
        </div>
      </template>
      <template #empty>
        <div class="table-no-data">
          <info-icon />
          <span>{{ $t("general.noData") }} </span>
        </div>
      </template>
      <template #head()="data">
        <div class="header-container">
          <span class="th-value">{{ data.label }}</span>
          <div class="tooltip-container" @click.stop>
            <tooltip :title="$t('stockPickingLab.tooltip.' + data.column)" />
          </div>
        </div>
      </template>

      <template #cell(rebalance)="data">
        <div class="my-report-container" v-if="isMyReport(data.item.id)">
          <report-indicator :type="MY_REPORT" />
        </div>
      </template>
      <template #cell(expiration)="item">
        <div class="state-cell">
          {{ formatDateToCommon(item.item.expiration) }}
          <report-indicator
            v-if="
              item.item.state.toLowerCase() === EXPIRED_REPORT.toLowerCase()
            "
            :type="item.item.state"
          />
        </div>
      </template>

      <template #cell(valid_from)="item">
        <div class="state-cell">
          {{ formatDateToCommon(item.item.valid_from) }}
          <report-indicator
            v-if="item.item.state.toLowerCase() === NEW_REPORT.toLowerCase()"
            :type="item.item.state"
          />
        </div>
      </template>

      <template #cell(profit)="data">
        <div class="flex button-detail align-center">
          <b-button variant="blank" @click="handleRowClick(data.item)">
            <more-icon v-if="!data.item._showDetails" :size="20" />
            <less-icon v-else :size="20" />
          </b-button>
          <div
            v-b-tooltip.hover
            :title="`${
              data.item.state.toLowerCase() == EXPIRED_REPORT.toLowerCase()
                ? $t('stockPickingLab.stoppedUpdating') + ' '
                : ''
            }${$t('general.lastUpdated')}: ${formatUpdatedDate(
              data.item.profit_last_update
            )}`"
          >
            <span
              class="profit"
              :class="{
                'profit-minus': parseFloat(data.item[selector]) < 0,
              }"
            >
              <arrow-up
                v-if="
                  data.item[selector] >= 0 &&
                  data.item.state.toLowerCase() !== EXPIRED_REPORT.toLowerCase()
                "
                :size="20"
              />
              <arrow-down
                v-else-if="
                  data.item[selector] < 0 &&
                  data.item.state.toLowerCase() !== EXPIRED_REPORT.toLowerCase()
                "
                :size="20"
              />
              <lock-clock v-else :size="16" />
              {{ data.item[selector] }}%
            </span>
          </div>
        </div>
      </template>
      <template
        #cell(reportDetail)="data"
        class="d-flex justify-content-center"
      >
        <div class="report-detail-cell">
          <router-link :to="`/stockPickingLab/reportDetail/${data.item.id}`">
            <report-icon :size="20" />
          </router-link>
        </div>
      </template>
      <template
        #cell(decomposedPdf)="data"
        class="d-flex justify-content-center"
      >
        <span class="ml-3">
          <b-spinner
            small
            v-if="
              decompositionPdfState.isDownloading &&
              decompositionPdfState.id === data.item.id
            "
          />
          <pdf-icon @click="getDecomposedPdf(data.item.id)" v-else />
        </span>
      </template>
      <template #row-details="row">
        <div>
          <report-detail
            :rowId="row.item.id"
            :isFavorite="isMyReport(row.item.id)"
            :strategy="strategy"
          />
        </div>
      </template>
    </b-table>
    <table-footer
      v-if="perPage && page && rows"
      :perPage="perPage"
      :pageSizes="pageSizes"
      :page="page"
      :totalRows="rows"
      @pageChange="handlePageChange"
      @perPageChange="handlePerPageChange"
    />
    <div
      v-if="id === NEW_REPORT && reports.length && upgradeText !== null"
      class="upgrade-panel"
    >
      <upgrade-card :text="upgradeText" :type="REPORT_UPGRADE" />
    </div>
  </div>
</template>

<script>
import {
  DATE_TIME_FORMAT,
  DETAIL_UPGRADE,
  EXPIRED_REPORT,
  ICONS_FILL_COLOR,
  LONG_ONLY,
  MY_REPORT,
  NEW_REPORT,
  NEW_REPORT_LIMITED_PRIVILEGE,
  NEW_REPORT_PRIVILEGE,
  REBALANCE_PRIVILEGE,
  REPORT_UPGRADE,
} from "../../utils/constants";

import { formatDateToCommon, strategyApiTranslator } from "../../utils/helper";
import { mapActions, mapGetters } from "vuex";
import ArrowDown from "vue-material-design-icons/ArrowBottomRight.vue";
import ArrowUp from "vue-material-design-icons/ArrowTopRight.vue";
import InfoIcon from "vue-material-design-icons/InformationOutline.vue";
import LessIcon from "vue-material-design-icons/UnfoldLessHorizontal.vue";
import LockClock from "vue-material-design-icons/LockClock.vue";
import MoreIcon from "vue-material-design-icons/UnfoldMoreHorizontal.vue";
import PdfIcon from "vue-material-design-icons/FileDownloadOutline.vue";
import ReportDetail from "./tableComponents/TableReportDetail.vue";
import ReportIcon from "vue-material-design-icons/TextBoxSearchOutline.vue";
import ReportIndicator from "./tableComponents/reportComponents/ReportIndicator.vue";
import TableFooter from "./tableComponents/TableFooter.vue";
import Tooltip from "../TooltipInfo.vue";
import UpgradeCard from "../UpgradeCard.vue";
import moment from "moment";

export default {
  components: {
    ArrowDown,
    ArrowUp,
    InfoIcon,
    LessIcon,
    LockClock,
    MoreIcon,
    ReportDetail,
    ReportIcon,
    ReportIndicator,
    TableFooter,
    Tooltip,
    UpgradeCard,
    PdfIcon,
  },
  props: {
    id: String,
    reports: Array,
    activeReports: Array,
    myReports: Array,
    myReportsActive: Boolean,
    headings: Array,
    page: Number,
    perPage: Number,
    rows: Number,
    isLoading: Boolean,
    strategy: Object,
  },
  data() {
    return {
      pageSizes: [5, 10, 20],
      activeRow: null,
      rowMenuOpen: false,
      currentItems: [],
      ICONS_FILL_COLOR,
      EXPIRED_REPORT,
      MY_REPORT,
      NEW_REPORT,
      NEW_REPORT_LIMITED_PRIVILEGE,
      NEW_REPORT_PRIVILEGE,
      REBALANCE_PRIVILEGE,
      DETAIL_UPGRADE,
      REPORT_UPGRADE,
      decompositionPdfState: {
        isDownloading: false,
        id: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
    upgradeText() {
      if (!this.user.permissions.includes(NEW_REPORT_LIMITED_PRIVILEGE)) {
        return this.$i18n.t("subscription.upgradeForReports");
      }

      if (!this.user.permissions.includes(NEW_REPORT_PRIVILEGE)) {
        return this.$i18n.t("subscription.upgradeForYearlyReports");
      }
      return null;
    },
    selector() {
      return this.strategy.value === LONG_ONLY ? "mean_20_longs" : "profit";
    },
  },
  methods: {
    ...mapActions("stockpickingLabStore", ["downloadDecomposedPDF"]),
    getDecomposedPdf(id) {
      this.decompositionPdfState = {
        isDownloading: true,
        id: id,
      };
      this.downloadDecomposedPDF(id).finally(
        () =>
          (this.decompositionPdfState = {
            isDownloading: false,
            id: null,
          })
      );
    },
    formatDateToCommon,
    handlePerPageChange(value) {
      this.$emit("perPageChange", value);
    },
    handlePageChange(value) {
      this.$emit("pageChange", value);
    },
    handleSortingChange(ctx) {
      this.$emit("sortChange", ctx.sortBy, ctx.sortDesc);
    },
    setActiveRow(id) {
      this.$emit("activeRowChange", id);
    },
    closeAllDetailRows() {
      this.currentItems.forEach((item) => {
        this.$set(item, "_showDetails", false);
      });
    },
    handleRowClick(item) {
      if (this.decompositionPdfState.id !== item.id) {
        this.setActiveRow(item.id);
        this.$set(item, "_showDetails", !item._showDetails);
      }
    },
    isMyReport(id) {
      if (
        this.myReports.find(
          (report) =>
            report.id === id &&
            report.strategy ===
              strategyApiTranslator(this.strategy.value, "api")
        )
      ) {
        return true;
      }
      return false;
    },
    rowClass(item) {
      if (item !== null && item._showDetails) {
        return "active-row";
      }
      return "";
    },
    formatUpdatedDate: (date) =>
      moment(date).format(`${DATE_TIME_FORMAT()} HH:mm`),
  },
  mounted() {
    this.setActiveRow(null);
  },
  watch: {
    currentItems(newValue, oldValue) {
      if (newValue.length && newValue !== oldValue) {
        if (this.id === NEW_REPORT) {
          this.$set(newValue[0], "_showDetails", true);
          this.setActiveRow(newValue[0].id);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  margin-bottom: toRem(40);
  .reports-table {
    border-radius: 5px;
  }
  .table-no-data {
    min-height: toRem(200);
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;
    font-style: italic;
    font-size: toRem(16);
    svg {
      fill: gray;
      margin-right: 0.5rem;
    }
  }
  .state-cell {
    display: flex;
    align-items: center;
    .report-status {
      margin-left: 1rem;
    }
  }
  .my-report-container {
    min-width: fit-content;
    margin-left: 0.5rem;
  }
  .profit {
    display: flex;
    justify-content: flex-start;
    color: #219653;
    font-weight: 800;
    align-items: center;
    &.profit-minus {
      color: #eb5757;
    }
    .material-design-icon {
      margin-right: 2px;
    }
  }
  .report-detail-cell {
    margin-left: toRem(25);
  }
}
.upgrade-cell {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  /* background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(0deg, rgba(250, 250, 250, 0.9), rgba(250, 250, 250, 0.9)); */
  background: #fdfdff;
}
.upgrade-panel {
  border-top: 1px solid $primaryColor;
}
#all {
  table {
    margin-bottom: toRem(48);
  }
  .container-footer {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
</style>