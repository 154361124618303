<template>
  <div
    :class="[
      {
        selected:
          !inSidebar &&
          !nameOnly &&
          selectedStock !== null &&
          selectedStock.report.id === report.id,
        inSidebar,
      },
      'ticker-container',
    ]"
    @click="$emit('click')"
  >
    <img
      :src="report.logo"
      class="ticker-logo"
      v-if="hasLogo"
      @error="hasLogo = false"
    />
    <gradient-icon :ticker="report.symbol" v-else />
    <div class="name-container">
      <div class="title-row">
        <div class="title">
          {{ report.symbol }}
        </div>
        <percentage-container
          v-if="!nameOnly"
          :percentage="priceMove"
          :simple="simple"
          units="%"
        />
      </div>
      <div class="subtitle">{{ report.name }}</div>
    </div>
    <score-mark
      v-if="!nameOnly"
      :score="report.score"
      :index="globalIndex"
      :length="allTickersLength"
      :simpleScore="false"
    />
  </div>
</template>

<script>
import { DAY, FIVE_DAYS, MONTH } from "../../../utils/constants";
import GradientIcon from "../../GradientIcon.vue";
import PercentageContainer from "./reportComponents/PercentageContainer.vue";
import ScoreMark from "./reportComponents/ScoreMark.vue";
import { getParsedObject } from "../../../utils/helper";
import { mapGetters } from "vuex";
export default {
  name: "TickerWithImage",
  components: {
    GradientIcon,
    PercentageContainer,
    ScoreMark,
  },
  props: {
    report: {
      type: Object,
      required: true,
    },
    globalIndex: {
      type: Number,
    },
    allTickersLength: {
      type: Number,
    },
    listType: {
      type: String,
    },
    simple: {
      type: Boolean,
    },
    nameOnly: {
      type: Boolean,
    },
    inSidebar: {
      type: Boolean,
    },
    canRebalance: Boolean,
    priceMoveTimeFrame: {
      type: String,
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      hasLogo: !!this.report.logo,
      DAY,
      MONTH,
      FIVE_DAYS,
    };
  },
  computed: {
    ...mapGetters("stockpickingLabStore", ["selectedStock"]),
    reportObject() {
      const data = this.getParsedObject(this.report);
      const indexes = {
        globalIndex: this.globalIndex,
        allTickersLength: this.allTickersLength,
      };
      data.indexes = indexes;
      return data;
    },
    priceMove() {
      switch (this.priceMoveTimeFrame) {
        case DAY:
          return Number(this.report.price_move_1d);
        case FIVE_DAYS:
          return Number(this.report.price_move_5d);
        case MONTH:
          return Number(this.report.price_move_1m);
        default:
          return this.report.price_move;
      }
    },
  },
  methods: {
    getParsedObject,
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="scss" scoped>
.ticker-container {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-right: 1rem;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  height: 60px;
  border-radius: toRem(15);
  min-width: calc(100% - 3rem);
  &.selected {
    box-shadow: 0px 0px 15px 0px #b1d0ff;
    min-width: 100%;
    padding: 0 0.5rem;
  }
  .name-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: auto;
    .title {
      font-size: toRem(20);
      color: #333;
      font-weight: 600;
    }
    .subtitle {
      font-size: toRem(12);
      color: #828282;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .price-move-container {
    min-width: toRem(60);
    font-weight: 600;
  }
  .ticker-logo {
    border-radius: 50%;
    max-width: toRem(40);
    margin-right: toRem(10);
  }
  .title-row {
    display: flex;
    .title {
      flex: 1;
    }
    .price-move-container {
      align-self: flex-end;
      min-width: 0;
      margin-right: toRem(10);
      width: fit-content;
      padding: 0;
    }
  }
  &.inSidebar {
    .price-move-container {
      padding: 0.1rem 0.5rem;
    }
  }
}

.stock-screening {
  .ticker-container {
    //border: 1px solid $blueBorders;
    padding: 0 0.5rem;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 1100px) and (max-width: 1200px) {
  .ticker-container {
    .score-mark-container {
      margin-left: auto;
    }
  }
}
</style>