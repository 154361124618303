<template>
  <basic-layout>
    <div class="reports-list-container">
      <div class="header-row">
        <router-link to="/stockPickingLab"> <arrow-left-icon /> </router-link>
        <h2>
          {{
            `${getTypeTranslation(timeframe)} ${strategy.name} ${$t(
              "stockPickingLab.reportsList"
            )}`
          }}
        </h2>
      </div>
      <performance-chart
        :timeframe="timeframe"
        :strategy="strategy.value"
        :routing="false"
      />
      <div class="scene-item">
        <reports-table
          id="ALL"
          :headings="mainFields"
          :reports="tableData"
          :activeReports="activeReport"
          :myReports="myReportsAll"
          :isFavorite="isFavorite"
          :page="page"
          :perPage="perPage"
          :rows="totalRows"
          :isLoading="isLoading.report"
          :strategy="strategy"
          @pageChange="handlePageChange"
          @perPageChange="handlePerPageChange"
          @sortChange="handleSortChange"
          @activeRowChange="addActiveReport"
        />
      </div>
    </div>
  </basic-layout>
</template>

<script>
import * as constants from "../utils/constants";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ArrowLeftIcon from "vue-material-design-icons/ArrowLeft.vue";
import BasicLayout from "../layouts/BasicLayout.vue";
import MainAlert from "../components/alerts/MainAlert.vue";
import PerformanceChart from "../components/charts/PerformanceChart.vue";
import ReportsTable from "../components/tables/ReportsTable";
import { getTypeTranslation } from "../utils/translationHelper";
import imageHint from "../assets/img/infographics/stockpickinglab_hint.png";
export default {
  components: {
    ArrowLeftIcon,
    ReportsTable,
    BasicLayout,
    PerformanceChart,
  },
  props: {
    isFavorite: {
      type: Boolean,
      default: false,
    },
  },
  name: "StockPickingLab",
  data() {
    return {
      imageHint: imageHint,
      mainFields: [
        {
          key: "profit",
          label: this.$i18n.t("general.performance"),
          thClass: "action-detail-header tooltip-header",
          sortable: true,
        },
        {
          key: "rebalance",
          label: this.$i18n.t("stockPickingLab.rebalanced"),
        },
        {
          key: "valid_from",
          label: this.$i18n.t("general.validFrom"),
          sortable: true,
          thClass: "tooltip-header",
        },
        {
          key: "expiration",
          label: this.$i18n.t("general.expirationDate"),
          sortable: true,
          thClass: "tooltip-header",
        },
        {
          key: "reportDetail",
          label: this.$i18n.t("stockPickingLab.reportDetail"),
        },
        {
          key: "decomposedPdf",
          label: this.$i18n.t("stockPickingLab.decomposedPdf"),
        },
      ],
      stateHeadingsNew: [
        {
          id: constants.NEW_REPORT,
          title: this.$i18n.t("stockPickingLab.navigation.new"),
        },
      ],
      filterHeadings: [
        {
          id: constants.ALL,
          title: this.$i18n.t("stockPickingLab.navigation.all"),
        },
        {
          title: getTypeTranslation(constants.DAILY_REPORT),
          id: constants.DAILY_REPORT,
        },
        {
          title: getTypeTranslation(constants.MONTHLY_REPORT),
          id: constants.MONTHLY_REPORT,
        },
        {
          title: getTypeTranslation(constants.YEARLY_REPORT),
          id: constants.YEARLY_REPORT,
        },
      ],
      filterValue: null,
      stateFilterValue: null,
      filterOpen: false,
      sort: {
        sortBy: "",
        desc: false,
      },
      showWelcomeModal: false,
      title: this.$i18n.t("stockPickingLab.stockPickingLab"),
      newReportConstant: constants.NEW_REPORT,
      strategy:
        constants
          .PREFERRED_STRATEGY_OPTIONS()
          .find((str) => str.value === this.$route.query.strategy) ||
        constants.PREFERRED_STRATEGY_OPTIONS()[0],
    };
  },
  methods: {
    getTypeTranslation,
    ...mapActions("stockpickingLabStore", {
      getReports: "getReports",
      getNewReports: "getNewReports",
      getReportsWithParams: "getReportsWithParams",
      getActiveReport: "getActiveReport",
      getFavoriteReports: "getFavoriteReports",
      getAllFavoriteReports: "getAllFavoriteReports",
      changeMyReports: "changeMyReports",
    }),
    ...mapMutations("stockpickingLabStore", {
      setPage: "setPage",
      setPerPage: "setPerPage",
    }),
    handlePageChange(value) {
      this.setPage(value);
      this.reloadReports();
    },
    handlePerPageChange(value) {
      this.setPerPage(value);
      this.reloadReports();
    },
    handleFilterChange(value) {
      this.filterValue = value.id;
      this.setPage(1);
      this.reloadReports();
    },
    handleNavigationChange(value) {
      if (value.id === constants.MY_REPORT) {
        this.stateFilterValue = null;
        this.isFavorite = true;
      } else {
        this.stateFilterValue = value.id;
        this.isFavorite = false;
      }
      this.setPage(1);
      this.addActiveReport(null);
      this.reloadReports();
    },
    handleSortChange(sortBy, sortDesc) {
      this.sort.sortBy = sortBy;
      this.sort.desc = sortDesc;
      this.reloadReports();
    },
    addActiveReport(value) {
      if (
        value === null ||
        this.activeReport.filter((item) => item.id === value).length === 0
      ) {
        this.getActiveReport(value).catch(() => {
          this.$toast.error({
            component: MainAlert,
            props: {
              message: this.$i18n.t("errors.cantGetData"),
            },
          });
        });
      }
    },
    getParams() {
      const params = {};
      params["type"] = this.timeframe;

      if (this.page) {
        params["page"] = this.page;
      }
      if (this.perPage) {
        params["page_size"] = this.perPage;
      }
      if (this.sort.sortBy !== "") {
        const ordering = !this.sort.desc
          ? "-" + this.sort.sortBy
          : this.sort.sortBy;
        params["ordering"] = ordering;
      }
      return params;
    },
    reloadReports() {
      const params = this.getParams();
      if (params) {
        if (this.isFavorite) {
          this.getFavoriteReports(params).catch(() => {
            this.$toast.error({
              component: MainAlert,
              props: {
                message: this.$i18n.t("errors.cantGetData"),
              },
            });
          });
        } else {
          this.getReportsWithParams(params).catch(() => {
            this.$toast.error({
              component: MainAlert,
              props: {
                message: this.$i18n.t("errors.cantGetData"),
              },
            });
          });
        }
      } else {
        if (this.isFavorite) {
          this.getAllFavoriteReports().catch(() => {
            this.$toast.error({
              component: MainAlert,
              props: {
                message: this.$i18n.t("errors.cantGetData"),
              },
            });
          });
        } else {
          this.getReports().catch(() => {
            this.$toast.error({
              component: MainAlert,
              props: {
                message: this.$i18n.t("errors.cantGetData"),
              },
            });
          });
        }
      }
    },
  },
  computed: {
    ...mapGetters("stockpickingLabStore", {
      reports: "getReports",
      newReports: "getNewReports",
      activeReport: "getActiveReport",
      myReports: "getFavoriteReportsActive",
      myReportsAll: "getFavoriteReports",
      isLoading: "getLoadingStatus",
      page: "getPage",
      perPage: "getPerPage",
      totalRows: "getTotalRows",
      rebalanceReport: "getRebalanceReport",
      tradingHours: "getMarketTradingHours",
    }),
    tableData() {
      if (this.isFavorite) {
        return this.myReports;
      }
      return this.reports;
    },
    timeframe() {
      return this.$route.params.timeframe;
    },
  },
  mounted() {
    if (window.innerWidth <= 768) {
      this.$toast.info({
        component: MainAlert,
        props: {
          message: this.$i18n.t("general.notSupported"),
        },
      });
    }
    if (!this.$cookies.get("welcomeModalShown")) {
      this.$bvModal.show("welcome-modal");
    }
    this.reloadReports();
    this.getNewReports().catch(() => {
      this.$toast.error({
        component: MainAlert,
        props: {
          message: this.$i18n.t("errors.cantGetData"),
        },
      });
    });
    this.getAllFavoriteReports().catch(() => {
      this.$toast.error({
        component: MainAlert,
        props: {
          message: this.$i18n.t("errors.cantGetData"),
        },
      });
    });
  },
  watch: {
    isFavorite(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.setPage(1);
        this.addActiveReport(null);
        this.reloadReports();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.reports-list-container {
  max-width: toRem(1900);
}
.header-row {
  margin-bottom: toRem(10);
  display: flex;
  .material-design-icon {
    margin-right: toRem(10);
  }
}
.help {
  padding-top: 25px;
  width: 25%;
}
.scene-item {
  padding-bottom: toRem(10);
}
</style>
