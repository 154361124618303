<template>
  <div class="flex horizontal-value-tooltip">
    <span :class="titleClass"> {{ name }}: </span>
    <span :class="[{ bold: bold }, 'value-container']">
      {{ value }}
    </span>
    <tooltip v-if="tooltip" :title="tooltip" />
  </div>
</template>
<script>
import Tooltip from "../TooltipInfo.vue";
export default {
  name: "card",
  components: {
    Tooltip,
  },
  props: {
    value: {
      type: [String, Number],
      description: "value",
    },
    name: {
      type: String,
      description: "Value name",
    },
    titleStyle: {
      type: String,
    },
    bold: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      description: "tooltip string",
    },
  },
  computed: {
    titleClass() {
      if (this.titleStyle) {
        return this.titleStyle + " title-container";
      }
      return "title-container";
    },
  },
};
</script>
<style lang="scss" scoped>
.flex {
  align-items: center;
}
.title-container {
  margin-right: toRem(5);
  color: $inactive;
  &.italic {
    font-style: italic;
  }
}
.value-container {
  margin-right: toRem(5);
  &.bold {
    font-weight: bold;
  }
}
</style>