<template>
  <div class="rebalance-countdown">
    <countdown
      :time="time"
      v-slot="{ totalDays, hours, minutes, seconds }"
      :transform="transformTimeSlotProps"
    >
      <span class="countdown-title"
        >{{ $t("stockPickingLab.nextRebalance") }}
      </span>
      <span class="daysOnly" v-if="totalDays > 1">
        {{ `${totalDays} ${$t("general.days")}` }}
      </span>
      <span class="watch" v-else> {{ hours }}:{{ minutes }}:{{ seconds }}</span>
    </countdown>
  </div>
</template>

<script>
import moment from "moment";
import { transformTimeSlotProps } from "../utils/helper";
export default {
  name: "rebalance-countdown",
  props: {
    nextRebalance: String,
  },
  data() {
    return {
      time:
        moment(this.nextRebalance).hour(22).minute(30).valueOf() -
        moment().valueOf(),
    };
  },
  methods: {
    transformTimeSlotProps,
  },
};
</script>

<style lang="scss" scoped>
.rebalance-countdown {
  margin-right: toRem(12);
  font-size: 13px;
  .countdown-title {
    display: block;
    color: black;
  }
  .watch,
  .daysOnly {
    color: #be1522;
    display: block;
    text-align: right;
    font-weight: 700;
  }
}
</style>