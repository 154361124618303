<template>
  <div class="rebalance-summary">
    <div class="flex">
      <h2>
        {{ $t("stockPickingLab.rebalanceModal.thirdSectionTitle") }}
        {{ " " + formatDateToCommon(date) }}
      </h2>
      <report-indicator :type="state" />
    </div>
    <div class="summary-inner" v-if="calculatedRebalance && !isCalculating">
      <p>
        {{ $t("stockPickingLab.rebalanceModal.thirdSectionDescription") }}
      </p>
      <div class="summary-data">
        <div class="summary-col">
          <div class="summary-data-row">
            <span class="row-name">
              {{ $t("stockPickingLab.rebalanceModal.investedAmount") }}:
            </span>
            <span class="row-data">
              {{ vueNumberFormat(calculatedRebalance.portfolio) }}
            </span>
          </div>
          <div class="summary-data-row">
            <span class="row-name">
              {{ $t("stockPicker.calculatedLong") }}:
            </span>
            <span class="row-data">
              {{ vueNumberFormat(calculatedRebalance.calculated_long) }}
            </span>
          </div>
          <div class="summary-data-row">
            <span class="row-name">
              {{ $t("stockPickingLab.rebalanceModal.predictionFrom") }}:
            </span>
            <span class="row-data">
              {{ formatDateToCommon(date) }}
            </span>
          </div>
        </div>
        <div class="summary-col">
          <div class="summary-data-row skip">
            <span class="row-name">
              {{ $t("stockPicker.calculatedShort") }}:
            </span>
            <span class="row-data">
              {{ vueNumberFormat(calculatedRebalance.calculated_short) }}
            </span>
          </div>
          <div class="summary-data-row">
            <span class="row-name">
              {{ $t("stockPickingLab.rebalanceModal.predictionTo") }}:
            </span>
            <span class="row-data">
              {{ predictionTo }}
            </span>
          </div>
        </div>
      </div>
      <div class="pdf-preview">
        <div v-if="loadedRatio !== 1" class="spinner-holder">
          <b-spinner />
        </div>
        <pdf
          v-for="i in numPages"
          :key="i"
          :src="pdfURL"
          :page="i"
          style="height: 100%"
          @progress="loadedRatio = $event"
        />
      </div>
    </div>
    <div class="spinner-box" v-else>
      <b-spinner />
    </div>
  </div>
</template>

<script>
import * as reportConstants from "../utils/constants";
import ReportIndicator from "./tables/tableComponents/reportComponents/ReportIndicator.vue";
import { formatDateToCommon } from "../utils/helper";
import pdf from "vue-pdf";
export default {
  name: "rebalance-summary",
  components: { ReportIndicator, pdf },
  props: {
    isCalculating: {
      type: Boolean,
    },
    calculatedRebalance: {
      type: Object,
    },
    type: {
      type: String,
    },
    date: {
      type: String,
    },
    state: {
      type: String,
    },
    rebalanceInPdf: {
      type: [File, Blob],
    },
    nextRebalance: {
      type: String,
    },
  },
  methods: {
    formatDateToCommon,
  },
  data() {
    return {
      loadedRatio: 0,
      numPages: 0,
    };
  },
  computed: {
    timeFrame() {
      if (this.type === reportConstants.DAILY_REPORT) {
        return "day";
      } else if (this.type === reportConstants.YEARLY_REPORT) {
        return "year";
      } else {
        return "month";
      }
    },
    predictionTo() {
      return this.formatDateToCommon(this.nextRebalance);
    },
    pdfURL() {
      if (this.rebalanceInPdf) {
        return window.URL.createObjectURL(this.rebalanceInPdf);
      } else {
        return null;
      }
    },
  },
  watch: {
    pdfURL(val) {
      const transformedPdf = pdf.createLoadingTask(val);
      transformedPdf.promise.then((pdf) => (this.numPages = pdf.numPages));
    },
  },
};
</script>

<style lang="scss" scoped>
.rebalance-summary {
  .spinner-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 25%;
  }
  h2 {
    margin-bottom: 0;
    padding-right: toRem(10);
    min-width: fit-content;
  }
  .flex {
    margin-bottom: toRem(20);
  }
  .summary-data {
    display: flex;
    .summary-col {
      flex: 1;
      &:nth-child(1) {
        padding-right: 40px;
      }
      .summary-data-row {
        &.skip {
          padding-top: 26px;
        }
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
        .row-name {
          font-weight: 500;
          color: $blueBorders;
        }
      }
    }
  }
  .pdf-preview {
    max-height: 400px;
    overflow: scroll;
    border-radius: 4px;
    margin-top: toRem(15);
    border: 1px solid $separator;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .spinner-box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 90px;
  }
}
</style>