<template>
  <li
    class="modal-nav-item"
    :class="{ active: step === currentStep, activeBar: step <= currentStep }"
  >
    <slot />
  </li>
</template>

<script>
export default {
  name: "modal-nav-item",
  props: {
    step: {
      type: Number,
    },
    currentStep: {
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-nav-item {
  border-radius: $navItemRadius;
  padding: toRem(12) toRem(12);
  position: relative;
  color: $inactive;
  &::before {
    content: "";
    width: 3px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #e8e8e8;
  }
  &.active {
    background-color: $blueBackground;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  &.activeBar {
    color: $textBlack;
  }
  &.activeBar::before {
    background-color: $blueBorders;
    transition: 0.4s background-color;
  }
}
</style>