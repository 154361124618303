<template>
  <div class="gradient-icon" :class="`v${variant}`">
    {{ ticker }}
  </div>
</template>

<script>
export default {
  name: "Gradient-icon",
  props: {
    ticker: {
      type: String,
    },
  },
  data() {
    return {
      variant: 1,
    };
  },
  beforeMount() {
    this.variant = Math.floor(Math.random() * (15 - 1 + 1)) + 1;
  },
};
</script>

<style lang="scss" scoped>
.gradient-icon {
  border-radius: 50%;
  width: toRem(40);
  height: toRem(40);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: toRem(10);
  color: white;
  font-weight: 600;
  font-size: toRem(11);
  &.v1 {
    background-image: linear-gradient(45deg, #bd00ff, #5200ff);
  }
  &.v2 {
    background-image: linear-gradient(45deg, #00d1ff, #5200ff);
  }
  &.v3 {
    background-image: linear-gradient(45deg, #ff00a8, #5200ff);
  }
  &.v4 {
    background-image: linear-gradient(45deg, #00ffc2, #5200ff);
  }
  &.v5 {
    background-image: linear-gradient(45deg, #ff5c00, #5200ff);
  }
  &.v6 {
    background-image: linear-gradient(45deg, #bd00ff, #00d1ff);
  }
  &.v7 {
    background-image: linear-gradient(45deg, #bd00ff, #ff00b8);
  }
  &.v8 {
    background-image: linear-gradient(45deg, #bd00ff, #ff6b00);
  }
  &.v9 {
    background-image: linear-gradient(45deg, #ff0000, #ffc700);
  }
  &.v10 {
    background-image: linear-gradient(45deg, #ff0000, #0038ff);
  }
  &.v11 {
    background-image: linear-gradient(45deg, #00fff0, #8f00ff);
  }
  &.v12 {
    background-image: linear-gradient(45deg, #ba0000, #ffa800);
  }
  &.v13 {
    background-image: linear-gradient(45deg, #ab00ba, #ffa800);
  }
  &.v14 {
    background-image: linear-gradient(45deg, #f364ff, #a40000);
  }
  &.v15 {
    background-image: linear-gradient(45deg, #fff500, #a40000);
  }
}
</style>