<template>
  <div class="stock-details">
    <horizontal-title-tooltip-section
      :title="$t('stockPickingLab.priceMove')"
      :tooltip="priceDefault ? $t('stockPickingLab.tooltip.priceMove') : $t('stockPickingLab.tooltip.priceMoveTimeframes')"
    />
    <div class="price-container">
      <div class="price-column">
        <horizontal-value-tooltip-section
          :value="openPrice"
          :name="priceDefault ? $t('stockPickingLab.priceOpen') : $t('stockPickingLab.priceClose') + ' ' + priceTime"
        />
        <horizontal-value-tooltip-section
          :value="closePrice"
          :name="priceDefault ? $t('stockPickingLab.priceClose') : $t('stockPickingLab.lastClose')"
        />
      </div>
      <percentage-container
        :percentage="priceMove"
        :simple="true"
        units="%"
      />
    </div>
    <horizontal-title-tooltip-section
      :title="$t('general.score')"
      :tooltip="$t('stockPickingLab.legend.score')"
    />
    <score-mark
      :score="stock.report.score"
      :index="stock.globalIndex"
      :length="stock.allTickersLength"
      :showTitle="true"
    />
  </div>
</template>

<script>
import HorizontalTitleTooltipSection from "../../inputs/HorizontalTitleTooltipSection.vue";
import HorizontalValueTooltipSection from "../../inputs/HorizontalValueTooltipSection.vue";
import PercentageContainer from "./reportComponents/PercentageContainer.vue";
import ScoreMark from "./reportComponents/ScoreMark.vue";
export default {
  components: {
    HorizontalTitleTooltipSection,
    PercentageContainer,
    ScoreMark,
    HorizontalValueTooltipSection,
  },
  name: "ticker-details",
  props: { stock: Object, priceMove: Number, openPrice: String, closePrice: String, priceDefault: Boolean, priceTime: String},
};
</script>

<style lang="scss" scoped>
.price-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: toRem(11);
  margin-bottom: toRem(12);
  .price-column {
    display: flex;
    flex-direction: column;
    .body-bold {
      font-weight: 400 !important;
    }
  }
  .price-move-container {
    font-size: toRem(14);
    padding-right: 0;
  }
}
.score-mark-container {
  width: 100%;
}
</style>