<template>
  <div v-if="report !== null && rowId === report.id" class="detail">
    <div class="flex align-center">
      <span class="title"
        >{{ strategy.name }} {{ $t("general.strategy") }}</span
      >
      <b-button
        variant="primary"
        v-b-modal="`rebalance-modal${report.id}`"
        v-if="user.permissions.includes(rebalanceOption)"
      >
        <rebalance-icon :size="20" />
        <span>{{ $t("stockPickingLab.rebalanceReport") }}</span>
      </b-button>
      <rebalance-modal
        :reportId="report.id.toString()"
        :date="report.valid_from"
        :type="report.type"
        :state="report.state"
        :isFavorite="isFavorite"
        :lastUpdated="report.profit_last_update"
        :nextRebalance="report.next_rebalance"
      />
    </div>
    <div class="detail-container">
      <div
        id="detail-column-long"
        :class="[{ longOnly: longOnly }, 'detail-column']"
      >
        <div class="flex">
          <span class="title">{{
            $t("stockPickingLab.undervaluedStocks")
          }}</span>
          <div class="margin-left-auto label">
            {{ $t("stockPickingLab.averagePriceMove") }}:
          </div>
          <percentage-container
            :percentage="Number(report.mean_20_longs)"
            units="%"
          />
        </div>
        <ul class="tickers-container">
          <li v-for="(item, index) in longTickers" :key="item.ticker">
            <div class="order-number">
              <span>{{ index + 1 }}.</span>
            </div>
            <ticker :report="item" :index="index" :length="tickers.length" />
          </li>
        </ul>
      </div>
      <div id="detail-column-short" class="detail-column" v-if="!longOnly">
        <div class="flex">
          <span class="title">{{
            $t("stockPickingLab.overvaluedStocks")
          }}</span>
          <div class="margin-left-auto label">
            {{ $t("stockPickingLab.averagePriceMove") }}:
          </div>
          <percentage-container
            :percentage="Number(report.mean_20_shorts)"
            units="%"
          />
        </div>
        <ul class="tickers-container">
          <li v-for="(item, index) in shortTickers" :key="item.ticker">
            <div class="order-number">
              <span>{{ index + 1 }}.</span>
            </div>
            <ticker :report="item" :index="index" :length="tickers.length" />
          </li>
        </ul>
      </div>
    </div>
  </div>
  <b-card v-else>
    <div class="table-loader">
      <b-spinner class="spinner"></b-spinner>
      <span>{{ $t("general.loading") }}...</span>
    </div>
  </b-card>
</template>

<script>
import * as constants from "../../../utils/constants";
import { getParsedObject, hasAccess } from "../../../utils/helper";
import PercentageContainer from "./reportComponents/PercentageContainer.vue";
import RebalanceIcon from "vue-material-design-icons/ChartMultiline.vue";
import RebalanceModal from "../../modals/RebalanceModal.vue";
import Ticker from "./TickerWithScore.vue";
import { mapGetters } from "vuex";
export default {
  name: "ReportDetail",
  components: { Ticker, PercentageContainer, RebalanceIcon, RebalanceModal },
  props: {
    rowId: {
      type: Number,
      required: true,
    },
    isFavorite: {
      type: Boolean,
      default: false,
    },
    strategy: {
      type: Object,
    },
  },
  data() {
    return {
      indexedFrom: 0,
      rebalanceOption: constants.REBALANCE_PRIVILEGE,
    };
  },
  computed: {
    ...mapGetters({ user: "getUser" }),
    ...mapGetters("stockpickingLabStore", {
      reports: "getActiveReport",
    }),
    report() {
      const reportsArray = this.getParsedObject(this.reports);
      const report = reportsArray.filter((report) => report.id === this.rowId);
      if (report.length === 1) {
        return report[0];
      }
      return null;
    },
    tickers() {
      return this.report.report_details;
    },
    longOnly() {
      return this.strategy.value === constants.LONG_ONLY;
    },
    longTickers() {
      return this.tickers
        .filter(
          (ticker) =>
            ticker.direction.toLowerCase() ===
            constants.TICKER_LONG.toLowerCase()
        )
        .sort((a, b) => (a.score < b.score ? 1 : -1))
        .slice(0, 20);
    },
    shortTickers() {
      return this.tickers
        .filter(
          (ticker) =>
            ticker.direction.toLowerCase() ===
            constants.TICKER_SHORT.toLowerCase()
        )
        .sort((a, b) => (a.score > b.score ? 1 : -1))
        .slice(0, 20);
    },
  },
  methods: {
    hasAccess,
    getParsedObject,
    getPaginatedList(list, page) {
      return list.slice((page - 1) * this.perPage, page * this.perPage);
    },
  },
};
</script>

<style lang="scss" scoped>
.detail {
  background: #f9fafc;
  padding-top: 0.5rem;
  .btn {
    margin-left: auto;
    margin-right: toRem(15);
  }
  .title {
    padding-left: 1rem;
    font-size: toRem(18);
    font-weight: bold;
  }
}
.detail-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background: #f9fafc;
  //border-bottom: 1px solid #bdbdbd;
  cursor: default;
  .detail-column {
    width: 50%;
    padding: 1rem;
    padding-bottom: 0;
    .title {
      padding: 0;
      font-size: toRem(14);
      font-weight: 500;
      //text-transform: uppercase;
    }
    .label {
      font-weight: 500;
      padding-right: 5px;
    }
    /*&:first-child {
      border-right: 1px solid rgba(54, 135, 255, 0.55);
    }*/
    &.longOnly {
      width: 100%;
      border-right: none;
      .tickers-container {
        display: flex;
        flex-wrap: wrap;
        min-height: toRem(270);
      }
    }
    .tickers-container {
      -webkit-column-count: 2; /* Chrome/Opera, Safari */
      -moz-column-count: 2; /* Mozilla Firefox */
      column-count: 2;
      break-inside: avoid;
      break-after: avoid;
      break-before: avoid;
      padding: 0;
      padding-top: 0.5rem;
      min-height: toRem(400);
      .spacer {
        height: 65px;
      }
      li {
        list-style-type: none;
        display: flex;
        margin-bottom: 0.05rem;
        .order-number {
          min-width: 1rem;
          margin-top: toRem(11);
          text-align: right;
        }
      }
    }
  }
}

@media only screen and (max-width: 1275px) {
  .detail-container {
    flex-direction: column;
    .detail-column {
      width: 100%;
      &:first-child {
        border-right: none;
      }
      &.longOnly {
        .tickers-container {
          -webkit-column-count: 2; /* Chrome/Opera, Safari */
          -moz-column-count: 2; /* Mozilla Firefox */
          column-count: 2;
          break-inside: avoid;
          break-after: avoid;
          break-before: avoid;
          display: block;
        }
      }
    }
  }
}
</style>