var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.selectedStock)?_c('div',{staticClass:"stock-sidebar"},[_c('div',{staticClass:"bar-heading"},[_c('p',{staticClass:"main-title"},[_vm._v(_vm._s(_vm.$t("stockPickingLab.stockDetail")))]),_c('close-icon',{on:{"click":_vm.handleClose}})],1),_c('ticker-with-image',_vm._b({attrs:{"nameOnly":!_vm.replacerActive,"inSidebar":""}},'ticker-with-image',_vm.selectedStock,false)),(
      _vm.canRebalance &&
      !_vm.replacerActive &&
      _vm.canDelete &&
      !_vm.discarded &&
      !_vm.hasRebalanced &&
      _vm.strategyAccess
    )?_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.setReplacerActive(true)}}},[_c('recycle-icon',{attrs:{"size":20}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("stockPickingLab.replaceStock")))])],1):_vm._e(),(!_vm.replacerActive || _vm.replacementStock)?_c('ticker-details',{attrs:{"stock":_vm.selectedStock,"priceMove":_vm.priceMove,"openPrice":_vm.openPrice,"closePrice":_vm.closePrice,"priceDefault":_vm.priceDefault,"priceTime":'(' + _vm.priceMoveTimeFrame.name + ')'}}):_vm._e(),(_vm.replacerActive)?_c('stock-replacer'):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }