var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container",attrs:{"id":_vm.id.toLowerCase()}},[_c('b-table',{ref:_vm.id,staticClass:"reports-table",attrs:{"id":"reports-table","show-empty":"","fixed":"","items":_vm.reports,"fields":_vm.headings,"busy":_vm.isLoading,"tbody-tr-class":_vm.rowClass,"no-local-sorting":true,"sort-icon-left":""},on:{"sort-changed":_vm.handleSortingChange,"row-clicked":function (item) { return _vm.handleRowClick(item); }},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"table-loader"},[_c('b-spinner',{staticClass:"spinner"}),_c('span',[_vm._v(_vm._s(_vm.$t("general.loading"))+"...")])],1)]},proxy:true},{key:"empty",fn:function(){return [_c('div',{staticClass:"table-no-data"},[_c('info-icon'),_c('span',[_vm._v(_vm._s(_vm.$t("general.noData"))+" ")])],1)]},proxy:true},{key:"head()",fn:function(data){return [_c('div',{staticClass:"header-container"},[_c('span',{staticClass:"th-value"},[_vm._v(_vm._s(data.label))]),_c('div',{staticClass:"tooltip-container",on:{"click":function($event){$event.stopPropagation();}}},[_c('tooltip',{attrs:{"title":_vm.$t('stockPickingLab.tooltip.' + data.column)}})],1)])]}},{key:"cell(rebalance)",fn:function(data){return [(_vm.isMyReport(data.item.id))?_c('div',{staticClass:"my-report-container"},[_c('report-indicator',{attrs:{"type":_vm.MY_REPORT}})],1):_vm._e()]}},{key:"cell(expiration)",fn:function(item){return [_c('div',{staticClass:"state-cell"},[_vm._v(" "+_vm._s(_vm.formatDateToCommon(item.item.expiration))+" "),(
            item.item.state.toLowerCase() === _vm.EXPIRED_REPORT.toLowerCase()
          )?_c('report-indicator',{attrs:{"type":item.item.state}}):_vm._e()],1)]}},{key:"cell(valid_from)",fn:function(item){return [_c('div',{staticClass:"state-cell"},[_vm._v(" "+_vm._s(_vm.formatDateToCommon(item.item.valid_from))+" "),(item.item.state.toLowerCase() === _vm.NEW_REPORT.toLowerCase())?_c('report-indicator',{attrs:{"type":item.item.state}}):_vm._e()],1)]}},{key:"cell(profit)",fn:function(data){return [_c('div',{staticClass:"flex button-detail align-center"},[_c('b-button',{attrs:{"variant":"blank"},on:{"click":function($event){return _vm.handleRowClick(data.item)}}},[(!data.item._showDetails)?_c('more-icon',{attrs:{"size":20}}):_c('less-icon',{attrs:{"size":20}})],1),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":("" + (data.item.state.toLowerCase() == _vm.EXPIRED_REPORT.toLowerCase()
              ? _vm.$t('stockPickingLab.stoppedUpdating') + ' '
              : '') + (_vm.$t('general.lastUpdated')) + ": " + (_vm.formatUpdatedDate(
            data.item.profit_last_update
          )))}},[_c('span',{staticClass:"profit",class:{
              'profit-minus': parseFloat(data.item[_vm.selector]) < 0,
            }},[(
                data.item[_vm.selector] >= 0 &&
                data.item.state.toLowerCase() !== _vm.EXPIRED_REPORT.toLowerCase()
              )?_c('arrow-up',{attrs:{"size":20}}):(
                data.item[_vm.selector] < 0 &&
                data.item.state.toLowerCase() !== _vm.EXPIRED_REPORT.toLowerCase()
              )?_c('arrow-down',{attrs:{"size":20}}):_c('lock-clock',{attrs:{"size":16}}),_vm._v(" "+_vm._s(data.item[_vm.selector])+"% ")],1)])],1)]}},{key:"cell(reportDetail)",fn:function(data){return [_c('div',{staticClass:"report-detail-cell"},[_c('router-link',{attrs:{"to":("/stockPickingLab/reportDetail/" + (data.item.id))}},[_c('report-icon',{attrs:{"size":20}})],1)],1)]}},{key:"cell(decomposedPdf)",fn:function(data){return [_c('span',{staticClass:"ml-3"},[(
            _vm.decompositionPdfState.isDownloading &&
            _vm.decompositionPdfState.id === data.item.id
          )?_c('b-spinner',{attrs:{"small":""}}):_c('pdf-icon',{on:{"click":function($event){return _vm.getDecomposedPdf(data.item.id)}}})],1)]}},{key:"row-details",fn:function(row){return [_c('div',[_c('report-detail',{attrs:{"rowId":row.item.id,"isFavorite":_vm.isMyReport(row.item.id),"strategy":_vm.strategy}})],1)]}}]),model:{value:(_vm.currentItems),callback:function ($$v) {_vm.currentItems=$$v},expression:"currentItems"}}),(_vm.perPage && _vm.page && _vm.rows)?_c('table-footer',{attrs:{"perPage":_vm.perPage,"pageSizes":_vm.pageSizes,"page":_vm.page,"totalRows":_vm.rows},on:{"pageChange":_vm.handlePageChange,"perPageChange":_vm.handlePerPageChange}}):_vm._e(),(_vm.id === _vm.NEW_REPORT && _vm.reports.length && _vm.upgradeText !== null)?_c('div',{staticClass:"upgrade-panel"},[_c('upgrade-card',{attrs:{"text":_vm.upgradeText,"type":_vm.REPORT_UPGRADE}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }