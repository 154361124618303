<template>
  <b-modal
    :id="modalId"
    class="modal modal-lg"
    size="lg"
    centered
    ref="rebalance-modal"
    hide-footer
    hide-header
    body-class="mbc"
    #default="{ close }"
    @hidden="handleHidden"
  >
    <div class="modal-body-content">
      <modal-navigation
        :currentStep="currentStep"
        :date="date"
        :type="type"
        :lastUpdated="lastUpdated"
        :state="state"
      />
      <div class="steps-container">
        <div class="blue-section">
          <close-icon @click="close" />
          <input-data
            v-if="currentStep === 0"
            @submit="handleInputDataSubmit"
            :defaultData="investedCapital"
          />
          <IBKRPortfolioForm
            v-if="currentStep === 1"
            @submit="handlePortfolioSubmit"
            :defaultData="currentPortfolio"
          />
          <rebalance-summary
            v-if="currentStep === 2"
            :isCalculating="isCalculating"
            :calculatedRebalance="calculatedRebalance"
            :type="type"
            :date="date"
            :state="state"
            :rebalanceInPdf="rebalanceInPdf"
            :nextRebalance="nextRebalance"
          />
        </div>
        <div class="rebalance-modal-footer">
          <div v-if="currentStep === 0" class="flex margin-right-auto">
            <!--             <b-form-checkbox
              v-model="autoFavorite"
              :value="true"
              class="auto-favorite-checkbox"
              >{{ $t("stockPickingLab.autoFavoriteReport") }}
            </b-form-checkbox> -->
          </div>
          <b-button
            v-if="currentStepData.buttons.includes('prev')"
            @click="handleChangeCurrentStep(currentStep - 1)"
            variant="primary"
          >
            {{ $t("general.buttons.prev") }}
          </b-button>
          <b-button
            v-if="currentStepData.buttons.includes('skip')"
            @click="handleSkip"
            variant="primary"
            class="skip-btn"
          >
            {{ $t("general.buttons.skip") }}
          </b-button>
          <b-button
            variant="secondary"
            type="submit"
            :form="currentStepData.form"
            v-if="currentStepData.buttons.includes('next')"
          >
            {{ $t("general.buttons.next") }}
          </b-button>
          <b-button
            v-if="currentStepData.buttons.includes('csv')"
            :disabled="!calculatedRebalance"
            :href="
              calculatedRebalance ? calculatedRebalance.download_basket_url : ''
            "
            download
            class="btn-csv"
          >
            {{ $t("stockPicker.downloadOutput") }}
          </b-button>
          <b-button
            v-if="currentStepData.buttons.includes('pdf')"
            :disabled="!calculatedRebalance"
            @click="handlePdf"
            download
          >
            {{ $t("stockPicker.downloadOutputPdf") }}
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import CloseIcon from "vue-material-design-icons/Close.vue";
import IBKRPortfolioForm from "../forms/rebalanceSteps/IBKRPortfolioForm.vue";
import InputData from "../forms/rebalanceSteps/InputData.vue";
import MainAlert from "../alerts/MainAlert.vue";
import ModalNavigation from "../navs/ModalNavigation.vue";
import RebalanceSummary from "../RebalanceSummary.vue";

export default {
  name: "RebalanceModal",
  components: {
    ModalNavigation,
    InputData,
    IBKRPortfolioForm,
    RebalanceSummary,
    CloseIcon,
  },
  props: {
    reportId: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    nextRebalance: {
      type: String,
      required: true,
    },
    state: {
      type: String,
      required: true,
    },
    lastUpdated: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      investedCapital: null,
      currentPortfolio: null,
      calculatedData: null,
      currentStep: 0,
      autoFavorite: true,
      selectedStrategyOption: null,
      steps: [
        {
          order: 0,
          form: "input-data-form",
          buttons: ["next"],
        },
        {
          order: 1,
          form: "ibkr-form",
          buttons: ["prev", "next", "skip"],
        },
        {
          order: 2,
          form: "",
          buttons: ["prev", "csv", "pdf"],
        },
      ],
    };
  },
  methods: {
    ...mapActions("stockpickingLabStore", ["calculateRebalance"]),
    ...mapMutations(["updateUser"]),
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleChangeCurrentStep(n) {
      this.currentStep = n;
    },
    handleInputDataSubmit({ investedCapital, selectedStrategyOption }) {
      this.investedCapital = investedCapital;
      this.selectedStrategyOption = selectedStrategyOption;
      this.handleChangeCurrentStep(1);
    },
    handlePortfolioSubmit(portfolio) {
      this.currentPortfolio = portfolio;
      this.handleChangeCurrentStep(2);
      this.calculateRebalance({
        investedCapital: Number(this.investedCapital),
        reportId: this.reportId,
        currentPortfolio: this.currentPortfolio,
        discarded: this.discardedTickers.map((ticker) => ticker.report.id),
        selectedStrategyOption: this.selectedStrategyOption,
        selected_longs_ids: this.filledTickersLong,
      })
        .then(() => {
          this.updateUser({
            favorite_reports: [
              ...this.user.favorite_reports,
              this.activeDetail,
            ],
          });
        })
        .catch((err) => {
          this.handleChangeCurrentStep(1);
          const message = err.response.status === 500 ? this.$i18n.t("errors.parseError") : this.$i18n.t("errors.cantGetData");
          this.$toast.error(message, {
            component: MainAlert,
            timeout: false
          });
        });
    },
    handlePdf() {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(this.rebalanceInPdf);
      link.download = "rebalance.pdf";
      link.click();
    },
    handleSkip() {
      this.calculateRebalance({
        investedCapital: Number(this.investedCapital),
        reportId: this.reportId,
        currentPortfolio: this.currentPortfolio,
        discarded: this.discardedTickers.map((ticker) => ticker.report.id),
        selectedStrategyOption: this.selectedStrategyOption,
        selected_longs_ids: this.filledTickersLong,
      })
        .then(() => {
          this.updateUser({
            favorite_reports: [
              ...this.user.favorite_reports,
              this.activeDetail,
            ],
          });
        })
        .catch(() => {
          this.$toast.error({
            component: MainAlert,
            props: {
              message: this.$i18n.t("errors.cantGetData"),
            },
          });
        });
      this.handleChangeCurrentStep(this.currentStep + 1);
    },
    handleHidden() {
      this.investedCapital = null;
      this.currentPortfolio = null;
      this.calculatedData = null;
      this.currentStep = 0;
    },
  },
  computed: {
    ...mapGetters("stockpickingLabStore", {
      isCalculating: "getIsCalculating",
      calculatedRebalance: "getCalculatedRebalance",
      rebalanceInPdf: "getRebalanceInPdf",
    }),
    ...mapGetters("stockpickingLabStore", [
      "discardedTickers",
      "activeDetail",
      "filledTickersLong",
    ]),
    ...mapGetters({
      user: "getUser",
    }),
    currentStepData() {
      return this.steps.find((s) => s.order === this.currentStep);
    },
    modalId() {
      return `rebalance-modal${this.reportId}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 0.625rem;
}
#rebalance-modal___BV_modal_body_ {
  padding: 0;
}
.modal {
  .modal-body-content {
    padding: 0;
    display: flex;
    min-height: 500px;
  }
  .steps-container {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    .blue-section {
      padding: toRem(20);
      padding-right: toRem(35);
      background-color: #f3f8ff;
      flex: 1;
      position: relative;
    }
    .rebalance-modal-footer {
      border-top: 1px solid $separator;
      display: flex;
      padding: 12px 16px;
      justify-content: flex-end;
      .btn.btn-primary {
        margin-right: auto;
        &.skip-btn {
          margin-right: 10px;
        }
      }
      .btn-csv {
        margin-right: 10px;
      }
    }
  }
}
.positions-container {
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0.5rem;
    width: 50%;
  }
}
.field-info {
  padding-top: toRem(5);
  padding-left: toRem(3);
  font-size: toRem(11);
  font-style: italic;
}
.input-label {
  color: $primaryColor;
  font-weight: 500;
}
.material-design-icon.close-icon {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 10px;
}
.auto-favorite-checkbox {
  margin-bottom: 7px;
}
</style>