var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    {
      selected:
        !_vm.inSidebar &&
        !_vm.nameOnly &&
        _vm.selectedStock !== null &&
        _vm.selectedStock.report.id === _vm.report.id,
      inSidebar: _vm.inSidebar,
    },
    'ticker-container' ],on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.hasLogo)?_c('img',{staticClass:"ticker-logo",attrs:{"src":_vm.report.logo},on:{"error":function($event){_vm.hasLogo = false}}}):_c('gradient-icon',{attrs:{"ticker":_vm.report.symbol}}),_c('div',{staticClass:"name-container"},[_c('div',{staticClass:"title-row"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.report.symbol)+" ")]),(!_vm.nameOnly)?_c('percentage-container',{attrs:{"percentage":_vm.priceMove,"simple":_vm.simple,"units":"%"}}):_vm._e()],1),_c('div',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.report.name))])]),(!_vm.nameOnly)?_c('score-mark',{attrs:{"score":_vm.report.score,"index":_vm.globalIndex,"length":_vm.allTickersLength,"simpleScore":false}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }