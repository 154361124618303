<template>
  <div class="ibkr-portfolio-form-container">
    <h2>{{ $t("stockPickingLab.rebalanceModal.secondSectionTitle") }}</h2>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form id="ibkr-form" @submit.prevent="handleSubmit(handleFormSubmition)">
        <ValidationProvider
          name="currentPortfolio"
          v-slot="{ errors, validate, failed }"
          rules="size:2000|ext:pdf|required"
        >
          <div class="form-item">
            <label>
              {{ $t("stockPicker.ibkrUploadFile") }}
            </label>
            <b-form-file
              v-model="currentPortfolio"
              :placeholder="
                currentPortfolio
                  ? currentPortfolio.name
                  : $t('stockPicker.rebalanceForm.currentPortfolioPlaceholder')
              "
              drop-placeholder='$t("stockPicker.rebalanceForm.currentPortfolioDroplaceholder")'
              accept="text/csv,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              @change="validate"
              :state="failed ? false : null"
              :browse-text="$t('stockPicker.rebalanceForm.browse')"
            />
            <p class="field-info" :class="{ hasError: errors[0] }">
              {{
                `${$t("stockPicker.rebalanceForm.acceptedFile")} ${
                  errors.length > 0 ? errors[0] : ""
                }`
              }}
            </p>
          </div>
        </ValidationProvider>
      </form>
      <div class="rebalance-modal-animation">
        <img src="../../../assets/img/infographics/IBKR_rebalance.png" alt="" />
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: "ibkr-portfolio-form",
  props: ["defaultData"],
  data() {
    return {
      currentPortfolio: this.defaultData,
    };
  },
  methods: {
    handleFormSubmition() {
      this.$emit("submit", this.currentPortfolio);
    },
  },
};
</script>

<style lang="scss" scoped>
.field-info {
  color: $inactive;
  margin-top: 5px;
  font-style: italic;
  font-size: toRem(11);
  &.hasError {
    color: $errorRedBorder;
  }
}
</style>