<template>
  <div
    :class="[
      { positive: percentage > 0, neutral: percentage == 0, simple: simple },
      'price-move-container',
    ]"
  >
    {{ percentage > 0 ? "+ " : percentage !== 0 ? "- " : "" }}{{ percentageNoSign
    }}{{ simple ? " " + units : units }}
  </div>
</template>
<script>
export default {
  name: "PercentageContainer",
  props: {
    percentage: {
      type: Number,
      required: true,
    },
    simple: {
      type: Boolean,
      default: false,
    },
    units: String,
  },
  computed: {
    percentageNoSign() {
      if (this.percentage < 0) {
        return this.percentage.toFixed(2).toString().substring(1);
      }
      return this.percentage;
    },
  },
};
</script>
<style lang="scss" scoped>
.price-move-container {
  border-radius: 5px;
  padding: 0.1rem 0.5rem;
  height: fit-content;
  width: fit-content;
  text-align: center;
  min-width: auto;
  color: white;
  background: #eb5757;
  white-space: nowrap;
  &.positive {
    background: #219653;
    &.simple {
      background: transparent;
      color: #219653;
    }
  }
  &.neutral{
    background: #219653;
    &.simple{
      color: #000;
    }
  }
  &.simple {
    background: transparent;
    color: #eb5757;
    font-weight: 500;
  }
}
</style>