<template>
  <div :class="computedClass">
    <icon-check v-if="type === MY_REPORT" :size="14" class="icon"/>
    <span>{{ getText() }}</span>
  </div>
</template>

<script>
import * as reportConstants from "../../../../utils/constants";
import IconCheck from "vue-material-design-icons/CheckBold.vue";
import {MY_REPORT} from "../../../../utils/constants";
export default {
  name: "ReportIndicator",
  components: {IconCheck},
  props: {
    type: {
      type: String,
      required: true,
    },
    indicatorClass: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      MY_REPORT
    }
  },
  methods: {
    getText() {
      switch (this.type) {
        case reportConstants.DAILY_REPORT:
          return this.$i18n.t("stockPickingLab.daily");
        case reportConstants.EXPIRED_REPORT:
          return this.$i18n.t("stockPickingLab.expired");
        case reportConstants.MONTHLY_REPORT:
          return this.$i18n.t("stockPickingLab.monthly");
        case reportConstants.MY_REPORT:
          return this.$i18n.t("stockPickingLab.rebalanced");
        case reportConstants.NEW_REPORT:
          return this.$i18n.t("stockPickingLab.latest");
        case reportConstants.OUTDATED_REPORT:
          return this.$i18n.t("stockPickingLab.outdated");
        case reportConstants.YEARLY_REPORT:
          return this.$i18n.t("stockPickingLab.annual");
        default:
          return "";
      }
    },
  },
  computed: {
    computedClass() {
      let className = "report-status ";
      if (this.indicatorClass) {
        className += this.indicatorClass;
      }

      className += ` ${this.type.toLowerCase()}`;

      return className;
    },
  },
};
</script>

<style lang="scss" scoped>
.report-status {
  width: fit-content;
  padding: 0px 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #414141;
  background: #fff;
  &::before {
    background: #414141;
  }
  &:before {
    content: "\A";
    border-radius: 50%;
    height: 5px;
    width: 5px;
    display: inline-block;
    margin-right: 0.5rem;
  }
  &.new {
    background: #cdffcd;
    color: #007f00;
    &:before {
      background: #007f00;
    }
  }
  &.expired {
    background: #ffe0e0;
    color: #d30000;
    &:before {
      background: #d30000;
    }
  }
  &.outdated {
    color: #4a4aff;
    background: #e6e6f2;
    &:before {
      background: #4a4aff;
    }
  }
  &.my-report {
    background: #f1f1f1;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon{
      margin-right: 0.2rem;
      padding-bottom: toRem(3);
    }
    &::before {
      display: none;
    }
  }
}
</style>