<template>
  <sidebars-layout
    :showRightBar="!!selectedStock"
    :showLeftBar="false"
    v-if="selectedStrategy"
  >
    <template v-slot:right>
      <stock-sidebar
        :hasRebalanced="hasRebalanced"
        :strategyAccess="showStrategies"
        :priceMoveTimeFrame="priceMoveTimeFrame"
      />
    </template>
    <div v-if="!activeReport || loading.report" class="scene-loader">
      <b-spinner class="spinner"></b-spinner>
    </div>
    <div class="content-container" v-else>
      <div class="stock-screening-switch" v-if="canSeeStrategies">
        <div class="switch-container">
          <span>{{ $t("products.SP_all_assets") }}</span>
          <block-switch v-model="stockScreeningOff">
            {{ $t("products.Investment_strategies") }}
          </block-switch>
        </div>
      </div>
      <div
        class="header-content"
        :class="[{ 'margin-bottom': canSeeStrategies && !stockScreeningOff }]"
      >
        <div class="main-title">
          <span>
            {{ $t("stockPickingLab.stockPickingLab") }}
          </span>
          <router-link to="/wizzard?noEdit=true" v-if="stockScreeningOff">
            <hint-icon />
          </router-link>
          <tooltip
            v-if="!stockScreeningOff"
            class="tooltip-container"
            :title="$t('stockPickingLab.tooltip.stockScreening')"
          />
          <router-link
            :to="`/stockPickingLab/editReport/${activeReport.id}`"
            v-if="user.isSuperUser && activeReport"
          >
            <cog-icon />
          </router-link>
        </div>
      </div>
      <div>
        <div v-if="!canSeeStrategies">
          <upgrade-card
            :type="REPORT_UPGRADE"
            :text="$t('subscription.upgradeForStrategy')"
          />
        </div>
        <div
          :class="[
            'flex width-100 align-baseline',
            { 'rebalanced-view': hasRebalanced },
          ]"
        >
          <div
            :class="[
              'price-monitor flex',
              { 'margin-left-auto': !showStrategies },
            ]"
            v-if="activeDetail.state !== EXPIRED_REPORT || !stockScreeningOff"
          >
            <multi-switch
              v-model="priceMoveTimeFrame"
              :className="showStrategies ? 'small box-border' : 'small'"
              :options="priceMoveOptions"
            />
            <tooltip
              class="tooltip-container"
              :title="
                showStrategies
                  ? $t('stockPickingLab.tooltip.priceMonitor')
                  : $t('stockPickingLab.tooltip.priceMonitorScreening')
              "
            />
          </div>
        </div>
        <div class="stock-screening">
          <stock-list
            :items="stockListAlfabetical"
            :parentItems="activeDetail.report_details"
            listType="rebalance"
            :canDelete="false"
            :canRebalance="false"
            :priceMoveTimeFrame="priceMoveTimeFrame.id"
            class="all-columns"
          />
        </div>
      </div>
    </div>
  </sidebars-layout>
</template>

<script>
import * as constants from "../utils/constants";
import {
  DEFAULT,
  EXPIRED_REPORT,
  ICONS_FILL_COLOR,
  LONG_ONLY,
  MONTHLY_REPORT,
  MY_REPORT,
  PREFERRED_STRATEGY_OPTIONS,
  PRICE_MOVE_TIME_FRAME_OPTIONS,
  REBALANCE_PRIVILEGE,
  REPORT_UPGRADE,
  SP_TIMEFRAMES_OPTIONS,
  TICKER_LONG,
  TICKER_SHORT,
} from "../utils/constants";
import { formatDateToCommon, strategyApiTranslator } from "../utils/helper";
import {
  getStrategyTranslations,
  getTypeTranslation,
} from "../utils/translationHelper";
import { mapActions, mapGetters, mapMutations } from "vuex";
import BlockSwitch from "../components/inputs/BlockSwitch.vue";
import CogIcon from "vue-material-design-icons/Cog.vue";
import HintIcon from "vue-material-design-icons/HelpCircleOutline";
import MainAlert from "../components/alerts/MainAlert.vue";
import MultiSwitch from "../components/inputs/MultiSwitch.vue";
import SidebarsLayout from "../layouts/SidebarsLayout.vue";
import StockList from "../components/StockList.vue";
import StockSidebar from "../components/sidebars/StockSidebar.vue";
import Tooltip from "../components/TooltipInfo.vue";
import UpgradeCard from "../components/UpgradeCard.vue";
import moment from "moment";
//import hintIcon from "vue-material-design-icons/HelpCircleOutline";
export default {
  components: {
    BlockSwitch,
    CogIcon,
    HintIcon,
    SidebarsLayout,
    StockList,
    StockSidebar,
    UpgradeCard,
    MultiSwitch,
    Tooltip,
  },
  name: "ReportDetail",
  data() {
    return {
      DEFAULT,
      MY_REPORT,
      LONG_ONLY,
      REBALANCE_PRIVILEGE,
      REPORT_UPGRADE,
      ICONS_FILL_COLOR,
      TICKER_SHORT,
      TICKER_LONG,
      EXPIRED_REPORT,
      MONTHLY_REPORT,
      reportType: null,
      date: this.activeReport?.valid_from,
      strategyOptions: PREFERRED_STRATEGY_OPTIONS(),
      selectedTimeframe: SP_TIMEFRAMES_OPTIONS()[1],
      selectedStrategy: null,
      priceMoveTimeFrame: null,
      stockScreeningOff: false,
    };
  },
  methods: {
    getStrategyTranslations,
    getTypeTranslation,
    formatDateToCommon,
    ...mapActions("stockpickingLabStore", {
      getReport: "getActiveReport",
      getActiveReportDetail: "getActiveReportDetail",
      getReports: "getReports",
      getRebalancedDetail: "getRebalancedDetail",
    }),
    ...mapMutations("stockpickingLabStore", [
      "setActiveDetail",
      "clearSelectedStock",
    ]),

    fetchLatestReport(source) {
      if (source && source === "manual") {
        const skippedIds = localStorage.getItem("skippedIds");
        localStorage.setItem(
          "skippedIds",
          skippedIds
            ? `${skippedIds},${this.activeDetail.id}`
            : this.activeDetail.id.toString()
        );
      }
      this.getActiveReportDetail(this.selectedTimeframe.value).catch(() => {
        this.$toast.error({
          component: MainAlert,
          props: {
            message: this.$i18n.t("errors.cantGetData"),
          },
        });
      });
    },
  },
  computed: {
    ...mapGetters("stockpickingLabStore", {
      activeReports: "getActiveReport",
      loading: "getLoadingStatus",
      activeDetail: "activeDetail",
      longTickers: "longTickers",
      shortTickers: "shortTickers",
      selectedStock: "selectedStock",
    }),
    ...mapGetters({ user: "getUser" }),
    activeReport() {
      return this.activeDetail;
    },
    stockListAlfabetical() {
      const list = this.activeDetail.report_details;
      return list.slice().sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    canSeeStrategies() {
      return constants.STRATEGIES.every((element) =>
        this.user.permissions.includes(element)
      );
    },
    canRebalance() {
      return this.user.permissions.includes(REBALANCE_PRIVILEGE);
    },
    showStrategies() {
      return this.canSeeStrategies && this.stockScreeningOff;
    },
    priceMoveOptions() {
      if (this.showStrategies) {
        return PRICE_MOVE_TIME_FRAME_OPTIONS();
      }
      return PRICE_MOVE_TIME_FRAME_OPTIONS().filter(
        (item) => item.id !== DEFAULT
      );
    },
    profitLongs() {
      switch (this.priceMoveTimeFrame.id) {
        case constants.DAY:
          return this.activeDetail.avg_long_profit_1d;
        case constants.FIVE_DAYS:
          return this.activeDetail.avg_long_profit_5d;
        case constants.MONTH:
          return this.activeDetail.avg_long_profit_1m;
        case constants.DEFAULT:
        default:
          return this.activeDetail.mean_20_longs;
      }
    },
    profitShorts() {
      switch (this.priceMoveTimeFrame.id) {
        case constants.DAY:
          return this.activeDetail.avg_shorts_profit_1d;
        case constants.FIVE_DAYS:
          return this.activeDetail.avg_shorts_profit_5d;
        case constants.MONTH:
          return this.activeDetail.avg_shorts_profit_1m;
        case constants.DEFAULT:
        default:
          return this.activeDetail.mean_20_shorts;
      }
    },
    timeframeOptions() {
      return SP_TIMEFRAMES_OPTIONS().filter(
        (item) => item.value === MONTHLY_REPORT
      );

      /* in the future when we have all 3 options
      if(this.user.tier === "T0"){
        return SP_TIMEFRAMES_OPTIONS().filter(item => item.value !== YEARLY_REPORT)
      }
      return SP_TIMEFRAMES_OPTIONS();*/
    },
    typeDescription() {
      return this.$i18n.t(
        "stockPickingLab.stateDescriptions." +
          this.activeDetail.state.toLowerCase()
      );
    },
    hasRebalanced() {
      if (!this.activeDetail) return false;
      return this.user.favorite_reports
        .map((rep) => rep.id)
        .includes(this.activeDetail.id);
    },
    isExpired() {
      return moment().isAfter(moment(this.activeDetail.expiration).hour(22));
    },
  },
  mounted() {
    const skippedIds = localStorage.getItem("skippedIds")
      ? localStorage.getItem("skippedIds").split(",")
      : [];

    if (this.user.first_login && !this.$cookies.get("wizzardFinished")) {
      this.$router.push("/wizzard");
    }
    // this.stockScreeningOff = this.canSeeStrategies;
    this.selectedStrategy =
      PREFERRED_STRATEGY_OPTIONS().find(
        (str) => str.value === this.user.preferred_strategy
      ) || PREFERRED_STRATEGY_OPTIONS()[0];
    const lastReportFromSelectedStrategy = this.user.favorite_reports.filter(
      (report) =>
        report.strategy ===
        strategyApiTranslator(this.selectedStrategy.value, "api")
    )[0];
    const paramId = this.$route.params.id;
    this.priceMoveTimeFrame = this.priceMoveOptions[0];
    if (paramId) {
      this.getRebalancedDetail(paramId).catch(() => {
        this.$toast.error({
          component: MainAlert,
          props: {
            message: this.$i18n.t("errors.cantGetData"),
          },
        });
      });
    } else if (
      lastReportFromSelectedStrategy &&
      !skippedIds.includes(lastReportFromSelectedStrategy.id.toString())
    ) {
      this.getRebalancedDetail(lastReportFromSelectedStrategy.id).catch(() => {
        this.$toast.error({
          component: MainAlert,
          props: {
            message: this.$i18n.t("errors.cantGetData"),
          },
        });
      });
    } else {
      this.fetchLatestReport();
    }
  },
  watch: {
    stockScreeningOff(newValue) {
      if (!newValue && this.priceMoveTimeFrame.id === DEFAULT) {
        this.priceMoveTimeFrame = this.priceMoveOptions[0];
      }
      this.clearSelectedStock();
      if (this.stockScreeningOff) {
        this.$router.push("/stockPickingLab");
      }
    },
    activeDetail(newValue, oldValue) {
      if (newValue !== oldValue && this.stockScreeningOff) {
        if (this.activeReport.report_details[0].open_price === 0) {
          this.$toast.info({
            component: MainAlert,
            props: {
              message: this.$i18n.t(
                "stockPickingLab.reportWaitingForMarketOpen"
              ),
            },
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.charts-container {
  &.hasRebalanced {
    display: flex;
    justify-content: space-between;
    .report-performance-chart {
      width: calc(50% - 10px);
    }
    .performance-chart {
      width: calc(50% - 10px);
    }
    @media screen and (max-width: $lg) {
      flex-wrap: wrap;
      .report-performance-chart,
      .performance-chart {
        width: 100%;
      }
    }
  }
}
.content-container {
  padding: toRem(15);
}
.header-content {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  width: calc(100% + toRem(15));
  margin-left: toRem(-15);
  margin-right: 0;
  margin-bottom: toRem(15);
  .tooltip-container {
    margin-left: toRem(5);
  }
  &.margin-bottom {
    margin-bottom: toRem(-30);
  }
  .switches-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    .switch-box {
      a {
        margin-bottom: toRem(12);
        margin-left: toRem(3);
      }
      &:first-of-type {
        margin-right: toRem(30);
      }
      display: flex;
      align-items: center;
      .selectbox-root {
        flex: 1;
      }
      width: 260px;
    }
  }
}
.main-title {
  margin: 0;
  display: flex;
  align-items: center;
  .help-circle-outline-icon {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0 toRem(5);
    color: $primaryColor;
  }
  .back-icon {
    margin-right: toRem(5);
    margin-left: -3px;
    svg {
      margin-bottom: toRem(5);
    }
  }
}

.chart-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: toRem(12);
  h3 {
    margin-right: toRem(10);
  }
}
.rebalanced-title {
  margin-top: toRem(-10);
  margin-bottom: toRem(10);
}
.rebalanced-view {
  flex-direction: column-reverse;
  .price-monitor {
    margin-top: toRem(10);
  }
}
.indicators-container {
  float: right;
  display: flex;
  margin-left: 5px;
  margin-right: auto;
  .detail-indicator {
    margin-right: 5px;
  }
}

.report-identification {
  margin-bottom: toRem(8);
  display: flex;
  align-items: baseline;
  .report-attr {
    margin-left: toRem(30);
    display: flex;
    align-items: center;
    .state-attr {
      margin-left: toRem(-15);
      margin-right: toRem(15);
    }
    &.right {
      margin-left: auto;
      .price-move-container {
        margin-left: toRem(5);
      }
    }
  }
}
.price-monitor {
  .tooltip-container {
    margin-left: toRem(5);
    min-width: toRem(20);
  }
  &.margin-left-auto {
    margin-right: 0;
    flex-direction: row-reverse;
    margin-bottom: 1rem;
    .tooltip-container {
      margin-right: toRem(5);
    }
  }
  align-items: center;
  font-size: toRem(12);
  width: fit-content;
  margin-right: 1rem;
}
.cards-container {
  display: flex;
  padding-bottom: 2rem;
  align-items: flex-start;
  .card {
    box-shadow: 0px 4px 4px 0px $blueShadow;
    border-color: $blueBorders;
    width: 50%;
    &:first-child {
      margin-right: 8px;
    }
    &.border-left-flat {
      border-top-left-radius: 0;
    }
    &:last-child {
      margin-left: 8px;
    }
    &.long-only {
      width: 100%;
      margin: 0;
    }
  }
}

.upgrade-container {
  border: 1px solid #a0a0a026;
  background: #fff;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 5px;
  margin-bottom: 1rem;
}
.button-container {
  display: flex;
  justify-content: space-between;
  max-width: 40%;
  .error {
    color: $errorRedBorder;
    max-width: 174px;
    margin-bottom: 0;
    text-align: block;
  }
  button {
    margin-right: 5px;
    min-width: 80px;
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.svg-icon {
  cursor: pointer;
}
.stock-screening-switch {
  margin-top: toRem(-20);
  span {
    margin-left: auto;
  }
}
@media only screen and (max-width: 1200px) {
  .header-content {
    .switches-container {
      display: block;
      margin-left: auto;
      margin-right: auto;
      flex: 0;
    }
  }
  .cards-container {
    flex-direction: column;
    .card {
      width: 100%;
      &.balanced-list {
        width: 100%;
      }
      &:first-child {
        margin-bottom: 1rem;
      }
      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>