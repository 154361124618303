<template>
    <div>
        <div v-if="type === DETAIL_UPGRADE" class="upgrade-container columns">
            <img src="../assets/img/lock_image.png" class="card-image" />
            <div class="upgrade-text">
                <div>{{$t("stockPickingLab.upgrade.currentSubscription")}}</div>
                <div> {{$t("stockPickingLab.upgrade.inThe")}} 
                    <router-link
                        tag="a"
                        class="link underline"
                        :to="{ name: 'reportDetail', params: { id: id } }"
                    >
                        Report detail
                    </router-link>
                    {{$t("stockPickingLab.upgrade.visiblePicks")}} </div>
                <div class="button-container">
                    <span>
                        <router-link
                        tag="a"
                        class="link blue"
                        :to="{ name: 'subscription'}"
                        >
                            {{$t("stockPickingLab.upgrade.unlock")}}
                        </router-link>
                         {{$t("stockPickingLab.upgrade.upgrade")}}
                    </span>
                </div>
            
            </div>
        </div>
        <div v-else-if="type === REPORT_UPGRADE" class="upgrade-container columns">
            <img src="../assets/img/infographics/upgrade.png" class="card-image margin-right-15" />
            <div class="upgrade-text">
                <h3>{{$t("subscription.upgradeTitle")}}</h3>
                <div class="subtitle">{{text}}</div>
                <b-button @click="handleUpgradeButtonClick()">
                    <unlock-icon />
                    {{$t("subscription.upgradeAccount")}}
                </b-button>
            
            </div>
        </div>
        <div v-else class="upgrade-container">
            <span class="upgrade-text">
                {{text}}
                {{type}}
            </span>
            <b-button @click="handleUpgradeButtonClick()">
                <unlock-icon />
            {{$t("subscription.upgradeAccount")}}
            </b-button>
        </div>
    </div>
</template>
<script >
import { DETAIL_UPGRADE, REPORT_UPGRADE} from "../utils/constants";
import UnlockIcon from "vue-material-design-icons/LockOpenCheckOutline.vue";
export default {
    name: "UpgradeCard",
    props:{
        text: {
            type: String,
            required: true
        },
        type: {
            type: String
        }, 
        id: {
            type: String
        }
    },
    data() {
        return {
            DETAIL_UPGRADE,
            REPORT_UPGRADE
        }
    },
    components: {
        UnlockIcon
    }, 
    methods: {
        handleUpgradeButtonClick(){
            this.$router.push("/profile/subscription");
        }
    }
}
</script>

<style lang="scss" scoped>
    .upgrade-container{
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: toRem(16);
        font-weight: 500;
        &.columns{
            flex-direction: row;
            margin: 0 auto;
        };
        .upgrade-text{
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            div{
                padding-bottom: 1rem;
            }
        }
        .margin-right-15{
            margin-right: toRem(15);
        }
        .card-image{
            max-height: 200px;
            height: auto;
            width: auto;
        }
        .subtitle{
            color: gray;
            font-size: toRem(12);
        }
    }
</style>