<template>
    <div class="flex">
        <span class="title"> {{title}} </span>
        <tooltip :title="tooltip" />
    </div>
</template>
<script>
import Tooltip from "../TooltipInfo.vue";
export default {
  name: "card",
  components: {
    Tooltip
  },
  props: {
    title: {
      type: String,
      description: "title",
    },
    tooltip: {
    type: String, 
      description: "tooltip string"
    }
  }
}; 
</script>
<style lang="scss" scoped>
.flex{
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: toRem(8);
    .title{
        font-weight: 500;
    }
}
</style>