var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-data-step"},[_c('h2',[_vm._v(_vm._s(_vm.$t("stockPickingLab.rebalanceModal.firstSectionTitle")))]),_c('div',{staticClass:"content-text"},[_vm._v(" "+_vm._s(_vm.$t("stockPickingLab.rebalanceModal.firstSectionDescription"))+" ")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"id":"input-data-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleFormSubmition)}}},[_c('select-box',{attrs:{"name":_vm.$t('preferences.preferredStrategy'),"options":_vm.preferredStrategyOptions,"label":"name","searchable":false,"clearable":false},model:{value:(_vm.selectedStrategyOption),callback:function ($$v) {_vm.selectedStrategyOption=$$v},expression:"selectedStrategyOption"}}),_c('ValidationProvider',{attrs:{"name":"investedCapital","rules":{
          regex: /[1-9]\d*/,
          min_value: {
            min: 5000,
          },
          max_value: 1000000000,
          required: true,
        },"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var failed = ref.failed;
return [_c('div',{staticClass:"form-item"},[_c('simple-form-input',{attrs:{"type":"number","label":_vm.$t('stockPicker.rebalanceForm.initialCapitalLabel'),"hasError":failed,"helperText":errors[0]},scopedSlots:_vm._u([{key:"trailingIcon",fn:function(){return [_vm._v(" $ ")]},proxy:true}],null,true),model:{value:(_vm.investedCapital),callback:function ($$v) {_vm.investedCapital=$$v},expression:"investedCapital"}})],1)]}}],null,true)})],1)]}}])}),_c('div',{staticClass:"calculated-holder"},[_c('p',[_c('span',{staticClass:"input-label"},[_vm._v(_vm._s(_vm.$t("stockPicker.calculatedShort"))+":")]),_vm._v(" "+_vm._s(_vm.shortPositions ? _vm.vueNumberFormat(_vm.shortPositions) : "$0.00")+" ")]),_c('p',[_c('span',{staticClass:"input-label"},[_vm._v(_vm._s(_vm.$t("stockPicker.calculatedLong"))+":")]),_vm._v(" "+_vm._s(_vm.longPositions ? _vm.vueNumberFormat(_vm.longPositions) : "$0.00")+" ")])]),_vm._m(0)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rebalance-modal-animation"},[_c('img',{attrs:{"src":require("../../../assets/img/infographics/input_data_animation.png"),"alt":""}})])}]

export { render, staticRenderFns }