<template>
  <b-card>
    <b-card-title>
      {{ title }}
    </b-card-title>
    <b-card-sub-title>
      {{ subtitle }}
      <tooltip class="tooltip-container" :title="tooltipDescription" />
      <span class="margin-left-auto label flex">
        {{ $t("stockPickingLab.averagePriceMove") }}
        <percentage-container
          :percentage="profit"
          units="%"
        />
      </span>
    </b-card-sub-title>
    <stock-list
      :items="items"
      :parentItems="activeDetail.report_details"
      listType="rebalance"
      :canDelete="longStrategy"
      :canRebalance="true"
      :priceMoveTimeFrame="priceMoveTimeFrame"
    />
    <ul class="discarded-tickers" v-if="typesDiscardedTickers.length > 0">
      <div class="dcs-header">
        <p class="body-bold">
          {{ $t("stockPickingLab.discardedTickers") }}
        </p>
        <b-button variant="blank" @click="resetDiscarded">
          <restart-icon :size="20" />
          {{ $t("stockPickingLab.returnToPrevState") }}</b-button
        >
      </div>
      <stock-list
        :items="discardedTickers"
        listType="discarded"
        :canRebalance="true"
        :canDelete="true"
        :priceMoveTimeFrame="priceMoveTimeFrame"
      />
    </ul>
  </b-card>
</template>
<script>
import { DEFAULT, TICKER_LONG } from "../utils/constants";
import { mapGetters, mapMutations } from "vuex";
import PercentageContainer from "../components/tables/tableComponents/reportComponents/PercentageContainer.vue";
import RestartIcon from "vue-material-design-icons/Restart.vue";
import StockList from "./StockList.vue";
//import Ticker from "./tables/tableComponents/TickerWithImage.vue";
import Tooltip from "./TooltipInfo.vue";
export default {
  name: "CardSlider",
  components: {
    PercentageContainer,
    RestartIcon,
    StockList,
    //Ticker,
    Tooltip,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    title: String,
    subtitle: String,
    type: String,
    profit: Number,
    priceMoveTimeFrame: String,
  },
  methods: {
    ...mapMutations("stockpickingLabStore", ["resetDiscarded"]),
  },
  computed: {
    ...mapGetters("stockpickingLabStore", ["activeDetail", "discardedTickers"]),
    longStrategy() {
      return this.type.toLowerCase() === TICKER_LONG.toLowerCase();
    },
    tooltipDescription() {
      if (this.longStrategy) {
        return this.$i18n.t("stockPickingLab.undervaluedDescription");
      }
      return this.$i18n.t("stockPickingLab.overvaluedDescription");
    },
    upgradeDescription() {
      if (this.longStrategy) {
        return this.$i18n.t("stockPickingLab.upgrade.undervaluedStocks");
      }
      return this.$i18n.t("stockPickingLab.upgrade.overvaluedStocks");
    },
    typesDiscardedTickers() {
      return this.discardedTickers.filter(
        (ticker) =>
          ticker.report.direction.toLowerCase() === this.type.toLowerCase()
      );
    },
  },
  data() {
    return {
      TICKER_LONG,
      DEFAULT,
      showMoreTickers: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.tickers-wrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  li {
    width: calc(1 / 3 * 100% - (1 - 1 / 3) * 40px);
  }
}
.tooltip-container {
  margin-left: toRem(5);
}
.card {
  position: relative;
  .card-subtitle {
    display: flex;
    align-items: center;
    font-size: toRem(13);
    margin-bottom: 1rem;
    .margin-left-auto {
      font-size: toRem(12);
    }
    .price-move-container {
      margin-left: toRem(10);
      font-size: toRem(12);
    }
  }
  ul {
    padding-left: 0;
  }
  li {
    list-style-type: none;
  }
  .card-title {
    color: #333333;
  }
  .card-body {
    padding-top: 1rem;
  }
  .ticker-container {
    margin-right: 0.5rem;
  }
  .VueCarousel {
    padding-top: 1rem;
    .VueCarousel-pagination {
      height: toRem(50);
    }
  }
  .btn-link {
    color: #333333;
    font-weight: 500;
    &:hover {
      text-decoration: none;
      color: $blueBorders;
      font-weight: 600;
    }
    &:focus {
      text-decoration: none;
    }
  }
  .dcs-header {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  .discarded-tickers {
    border-bottom: 1px solid $blueBorders;
    border-top: 1px solid $blueBorders;
    .body-bold {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
  .discarded-tickers {
    background: repeating-linear-gradient(
      -45deg,
      rgba(212, 212, 212, 0.2),
      rgba(212, 212, 212, 0.2) 10px,
      #ffffff 10px,
      #ffffff 20px
    );
    .ticker-container {
      &.selected {
        background: #fff;
      }
    }
  }
  .sub-btn-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    text-align: center;
    font-weight: 400;
    font-size: toRem(13);
  }
}
@media only screen and (max-width: 1600px) {
  .tickers-wrapper {
    column-gap: 20px;
    li {
      width: calc(1 / 2 * 100% - (1 - 1 / 2) * 20px);
    }
  }
}
</style>
