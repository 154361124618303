<template>
  <countdown
    v-if="!isCountdownLoading"
    :time="countdownTime"
    v-slot="{ totalHours, minutes, seconds }"
    emit-events
    @end="handleEnd"
    :transform="transformTimeSlotProps"
  >
    <h3>
      {{ $t("marketWatch.market") }}
      {{ isOpen ? $t("marketWatch.close") : $t("marketWatch.open") }}
      {{ $t("marketWatch.in") }}:
      <span class="watch">{{ totalHours }}:{{ minutes }}:{{ seconds }}</span>
    </h3>
  </countdown>
  <b-spinner v-else small />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { transformTimeSlotProps } from "../utils/helper";
export default {
  name: "market-countdown",
  data() {
    return {
      countdownTime: null,
      isCountdownLoading: true,
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters("stockpickingLabStore", {
      tradingHours: "getMarketTradingHours",
    }),
  },
  methods: {
    ...mapActions("stockpickingLabStore", ["getMarketTradingHours"]),
    handleEnd() {
      if (moment(this.tradingHours.market_close).isAfter(moment())) {
        this.setCountdownTime();
      } else {
        this.isCountdownLoading = true;
        this.getMarketTradingHours()
          .then(() => this.setCountdownTime())
          .finally(() => (this.isCountdownLoading = false));
      }
    },
    setCountdownTime() {
      const subtractFrom = moment(this.tradingHours.market_open).isAfter(
        moment()
      )
        ? this.tradingHours.market_open
        : this.tradingHours.market_close;
      if (this.tradingHours.market_close === subtractFrom) {
        this.isOpen = true;
      } else {
        this.isOpen = false;
      }
      this.countdownTime = moment(subtractFrom).valueOf() - moment().valueOf();
    },
    transformTimeSlotProps,
  },
  mounted() {
    this.getMarketTradingHours()
      .then(() => this.setCountdownTime())
      .finally(() => (this.isCountdownLoading = false));
  },
};
</script>

<style lang="scss" scoped>
.watch {
  font-weight: 700;
  color: $blueBorders;
  width: 70px;
  display: inline-block;
  padding-right: 10px;
}
</style>