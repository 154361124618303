<template>
  <div v-b-tooltip.hover :title="title">
    <InformationOutline
      class="tooltip-color"
      :size="20"
      :fillColor="fillColor"
    />
  </div>
</template>

<script>
import InformationOutline from "vue-material-design-icons/InformationOutline";

export default {
  name: "tooltip",
  components: { InformationOutline },
  props: {
    title: {
      type: String,
      description: "Contains the text that the tooltip will display",
    },
    fillColor: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip-color {
  color: $primaryColor;
}
</style>